import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePatch from 'hooks/usePatch';
import { toast } from 'react-toastify'; // Import toast for notifications
import ReactDatePicker from 'react-datepicker';
import Loading from 'pages/Loader/Loading';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function EditStorieData({ setModalopens, modalopens, fetchData, editData }) {
  const { mutateAsync: EditPlan } = usePatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageviewPreview, setImageviewPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const defaultUserData = {
    id: '',
    storiesTitle: '',
    externalLink: '',
    storyEndDate: '',
    storyViewImg: null,
    storiesImage: null,
    isActive: false
  };
  const [userData, setUserData] = useState(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  const [endDate, setEndDate] = useState(userData ? userData.storyEndDate : ''); // Set default value

  useEffect(() => {
    setUserData(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  }, [editData]);

  const handleClose = () => {
    setModalopens(false);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const submitForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('_id', userData?._id);
    formData.append('storiesTitle', userData?.storiesTitle);
    formData.append('externalLink', userData?.externalLink);
    formData.append('storyEndDate', endDate || userData?.storyEndDate);
    formData.append('isActive', userData?.isActive);
    formData.append('storiesImage', userData?.storiesImage);
    formData.append('storyViewImg', userData?.storyViewImg);

    EditPlan({
      url: 'stories',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);

        toast.success('Story Successfully Updated', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
        setModalopens(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // Handle file input change

  const handleStorieFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);

      setUserData((prevData) => ({
        ...prevData,
        storiesImage: file
      }));
    }
  };

  const handleViewFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImageviewPreview(imageUrl);

      setUserData((prevData) => ({
        ...prevData,
        storyViewImg: file
      }));
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Edit Story
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="storiesTitle">Title</InputLabel>
                <OutlinedInput
                  id="storiesTitle"
                  type="text"
                  name="storiesTitle"
                  placeholder="Enter Title"
                  fullWidth
                  defaltValue={userData?.storiesTitle}
                  value={userData?.storiesTitle}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            {/* Link */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">Link</InputLabel>
                <OutlinedInput
                  id="externalLink"
                  type="text"
                  name="externalLink"
                  placeholder="Enter Link"
                  fullWidth
                  defaltValue={userData?.externalLink}
                  value={userData?.externalLink}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>
            {/* End date */}
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">Start Date</InputLabel>
                <div className="date-range-select">
                  <ReactDatePicker
                    disabled
                    className="pppppp"
                    style={{ width: '100%', height: '41px', border: '1px solid #d9d9d9', borderRadius: '3px' }}
                    selected={userData?.storyStartDate}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">End Date</InputLabel>
                <div className="date-range-select">
                  <ReactDatePicker
                    className="pppppp"
                    style={{ width: '100%', height: '41px', border: '1px solid #d9d9d9', borderRadius: '3px' }}
                    selected={endDate || userData?.storyEndDate}
                    onChange={(date) => {
                      setEndDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    dateFormat="yyyy-MM-dd"
                    minDate={editData?.storyStartDate}
                  />
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <InputLabel htmlFor="isActive">Status</InputLabel>
                <Select labelId="isActive-label" id="isActive" value={userData.isActive} onChange={handleChange} fullWidth name="isActive">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </Stack>
            </Grid>
            {/* isactive */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleStorieFileChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '-5px'
                  }}
                  htmlFor="input-file"
                >
                  Select A Story Image
                </label>
                {!imagePreview && (
                  <img
                    src={userData?.storiesImage}
                    crossOrigin="anonymous"
                    alt="Preview"
                    style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                  />
                )}
                {imagePreview && (
                  <>
                    <img src={imagePreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-files" accept="image/*" onChange={handleViewFileChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '-5px'
                  }}
                  htmlFor="input-files"
                >
                  Select A Story View Image
                </label>
                {!imageviewPreview && (
                  <img
                    src={userData?.storyViewImg}
                    crossOrigin="anonymous"
                    alt="Preview"
                    style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                  />
                )}
                {imageviewPreview && (
                  <>
                    <img src={imageviewPreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submitForm} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
