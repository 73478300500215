import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Drawer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  List,
  ListItem,
  FormControl,
  ListItemText,
  Select,
  Divider,
  MenuItem,
  ListItemIcon
} from '@mui/material';
import { FilterOutlined, EditOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { ConfigurationColumn } from 'constants/Table-columns/TableColumn';
import { toast } from 'react-toastify';
import Dot from 'components/@extended/Dot';
import useGet from 'hooks/useGet';
import 'react-datepicker/dist/react-datepicker.css';
import useDelete from 'hooks/useDelete';
import Loading from 'pages/Loader/Loading';
import AddConfiguration from './AddConfiguration';
import EditConfiguration from './EditConfiguration';

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead sx={{ backgroundColor: '#ddd' }}>
      <TableRow>
        {ConfigurationColumn.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ pt: '12px', pb: '12px' }}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

const OrderStatus = ({ isActive }) => {
  let color;
  let title;

  switch (isActive) {
    case true:
      color = 'success';
      title = 'Active';
      break;
    case false:
      color = 'error';
      title = 'In Active';
      break;
    default:
      color = 'primary';
      title = 'None';
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        justifyContent: 'end'
      }}
    >
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  isActive: PropTypes?.bool
};

const Configuration = ({ openAddModal, setOpenAddModal }) => {
  const { mutateAsync: UserListGet } = useGet();
  const { mutateAsync: DeleteMemberPlan } = useDelete();
  const [order] = useState('asc');
  const [orderBy] = useState('trackingNo');
  const [selected] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [statusfilter, setStatusfilter] = useState('');
  const [loading, setLoading] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const timerRef = useRef(null);
  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      fetchData();
    }, 1000);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [debouncedSearchQuery, currentPage, itemsPerPage, UserListGet]);

  useEffect(() => {
    fetchData();
  }, [statusfilter, searchQuery]);

  const debouncedHandleSearch = debounce((value) => {
    setDebouncedSearchQuery(value);
  }, 2000);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await UserListGet({
        url: `configuration`,
        type: 'details',
        token: true
      });
      setUserList(res);
      setTotalPages(res?.meta?.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const DeleteTransformationModal = (row) => {
    setDeleteModal(true);
    setDeleteId(row);
  };

  const DeleteTransformation = (row) => {
    setLoading(true);
    DeleteMemberPlan({
      url: `transformation/${deleteId.id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setLoading(false);
        toast.success('Transformation Deleted Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const handleChangeStatus = (event) => {
    setStatusfilter(event.target.value);
  };

  const [modalopens, setModalopens] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [editData, setEditData] = useState('');

  const DetailModalopen = (row) => {
    setDetailModalOpen(true);
    setEditData(row);
  };

  const Modalopen = (row) => {
    setModalopens(true);
    setEditData(row);
  };

  const filterSearch = () => {
    fetchData();
    setOpenDrawer(false);
  };
  const CloseDrower = () => {
    setOpenDrawer(false);
  };
  const clearFilter = () => {
    setStatusfilter('');
    setSearchQuery('');
  };

  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation">
      <Typography variant="h3" sx={{ textDecoration: 'none', px: 3, py: 2, position: 'relative' }}>
        <FilterOutlined /> Filter
        <Box sx={{ position: 'absolute', right: '10px', top: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={CloseDrower}>
          <CloseOutlined />
        </Box>
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label>Status Filter</label>
              <Select fullWidth sx={{ width: '100%' }} value={statusfilter} onChange={handleChangeStatus}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemIcon sx={{ width: '100%', mt: 4, gap: 4 }}>
            <Button variant="contained" color="secondary" onClick={CloseDrower}>
              Cancel
            </Button>
            <Button variant="contained" onClick={filterSearch}>
              Submit
            </Button>
          </ListItemIcon>
          <ListItemText />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      {/* {userList?.map((row, index) => {
        <Box sx={{ display: 'flex' }}>
          <Box>
            <h3>{row.key} : </h3>
          </Box>
          <Box sx={{ marginLeft: '10px', marginTop: '22px' }}>
            <textarea>{row.Valuw}</textarea>
          </Box>
        </Box>
      })} */}
      <Box>
        <TableContainer
          sx={{
            width: '100%',
            overflowX: 'auto',
            position: 'relative',
            display: 'block',
            maxWidth: '100%',
            '& td, & th': { whiteSpace: 'nowrap' }
          }}
        >
          {userList?.map((row, index) => {
            return (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ minWidth: '115px', display: 'flex', justifyContent: 'end' }}>
                  <h3>{row.key} : </h3>
                </Box>
                <Box sx={{ marginLeft: '10px', marginTop: '22px' }}>
                  <textarea style={{ width: '840px', height: '75px', fontSize: '16px' }} value={row?.value} />
                </Box>
                <Box sx={{ marginLeft: '10px', marginTop: '22px' }}>
                  <span style={{ fontSize: '20px' }}>
                    <EditOutlined onClick={() => Modalopen(row)} />
                  </span>
                </Box>
              </Box>
            );
          })}
          {/* </TableBody> */}
        </TableContainer>
      </Box>
      <AddConfiguration fetchData={fetchData} modalopens={openAddModal} setModalopens={setOpenAddModal} editData={editData} />
      <EditConfiguration fetchData={fetchData} modalopens={modalopens} setModalopens={setModalopens} editData={editData} />
      <Loading loading={loading} />
    </>
  );
};
export default Configuration;

// import PropTypes from 'prop-types';
// import { useState, useEffect, useRef } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import {
//   Box,
//   Button,
//   Link,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   Drawer,
//   TableHead,
//   TableRow,
//   Typography,
//   TextField,
//   List,
//   ListItem,
//   FormControl,
//   ListItemText,
//   Select,
//   Divider,
//   MenuItem,
//   ListItemIcon
// } from '@mui/material';
// import { FilterOutlined, EditOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
// import { ConfigurationColumn } from 'constants/Table-columns/TableColumn';
// import { toast } from 'react-toastify';
// import Dot from 'components/@extended/Dot';
// import useGet from 'hooks/useGet';
// import 'react-datepicker/dist/react-datepicker.css';
// import useDelete from 'hooks/useDelete';
// import Loading from 'pages/Loader/Loading';
// import AddConfiguration from './AddConfiguration';
// import EditConfiguration from './EditConfiguration';

// function OrderTableHead({ order, orderBy }) {
//   return (
//     <TableHead sx={{ backgroundColor: '#ddd' }}>
//       <TableRow>
//         {ConfigurationColumn.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             sx={{ pt: '12px', pb: '12px' }}
//             align={headCell.align}
//             padding={headCell.disablePadding ? 'none' : 'normal'}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             {headCell.label}
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

// const debounce = (func, delay) => {
//   let timeoutId;
//   return (...args) => {
//     if (timeoutId) clearTimeout(timeoutId);
//     timeoutId = setTimeout(() => {
//       func.apply(null, args);
//     }, delay);
//   };
// };

// const OrderStatus = ({ isActive }) => {
//   let color;
//   let title;

//   switch (isActive) {
//     case true:
//       color = 'success';
//       title = 'Active';
//       break;
//     case false:
//       color = 'error';
//       title = 'In Active';
//       break;
//     default:
//       color = 'primary';
//       title = 'None';
//   }

//   return (
//     <Stack
//       direction="row"
//       spacing={1}
//       alignItems="center"
//       sx={{
//         justifyContent: 'end'
//       }}
//     >
//       <Dot color={color} />
//       <Typography>{title}</Typography>
//     </Stack>
//   );
// };

// OrderStatus.propTypes = {
//   isActive: PropTypes?.bool
// };

// const Configuration = ({ openAddModal, setOpenAddModal }) => {
//   const { mutateAsync: UserListGet } = useGet();
//   const { mutateAsync: DeleteMemberPlan } = useDelete();
//   const [order] = useState('asc');
//   const [orderBy] = useState('trackingNo');
//   const [selected] = useState([]);
//   const [userList, setUserList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10);
//   const [totalPages, setTotalPages] = useState('');
//   const [searchQuery, setSearchQuery] = useState('');
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const [statusfilter, setStatusfilter] = useState('');
//   const [loading, setLoading] = useState(false);

//   const [deleteModal, setDeleteModal] = useState(false);
//   const [deleteId, setDeleteId] = useState();

//   const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
//   const timerRef = useRef(null);
//   useEffect(() => {
//     clearTimeout(timerRef.current);

//     timerRef.current = setTimeout(() => {
//       fetchData();
//     }, 1000);
//     return () => {
//       clearTimeout(timerRef.current);
//     };
//   }, [debouncedSearchQuery, currentPage, itemsPerPage, UserListGet]);

//   useEffect(() => {
//     fetchData();
//   }, [statusfilter, searchQuery]);

//   const debouncedHandleSearch = debounce((value) => {
//     setDebouncedSearchQuery(value);
//   }, 2000);

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const res = await UserListGet({
//         url: `configuration`,
//         type: 'details',
//         token: true
//       });
//       setUserList(res);
//       setTotalPages(res?.meta?.totalPages);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const DeleteTransformationModal = (row) => {
//     setDeleteModal(true);
//     setDeleteId(row);
//   };

//   const DeleteTransformation = (row) => {
//     setLoading(true);
//     DeleteMemberPlan({
//       url: `transformation/${deleteId.id}`,
//       type: 'details',
//       token: true
//     })
//       .then((res) => {
//         setLoading(false);
//         toast.success('Transformation Deleted Successfully', {
//           position: toast.POSITION.BOTTOM_RIGHT
//         });
//         fetchData();
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

//   const handleNextPage = () => {
//     setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
//   };

//   const handlePrevPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };

//   const toggleDrawer = (newOpen) => () => {
//     setOpenDrawer(newOpen);
//   };

//   const handleChangeStatus = (event) => {
//     setStatusfilter(event.target.value);
//   };

//   const [modalopens, setModalopens] = useState(false);
//   const [detailModalOpen, setDetailModalOpen] = useState(false);
//   const [editData, setEditData] = useState('');

//   const DetailModalopen = (row) => {
//     setDetailModalOpen(true);
//     setEditData(row);
//   };

//   const Modalopen = (row) => {
//     setModalopens(true);
//     setEditData(row);
//   };

//   const filterSearch = () => {
//     fetchData();
//     setOpenDrawer(false);
//   };
//   const CloseDrower = () => {
//     setOpenDrawer(false);
//   };
//   const clearFilter = () => {
//     setStatusfilter('');
//     setSearchQuery('');
//   };

//   const DrawerList = (
//     <Box sx={{ width: 350 }} role="presentation">
//       <Typography variant="h3" sx={{ textDecoration: 'none', px: 3, py: 2, position: 'relative' }}>
//         <FilterOutlined /> Filter
//         <Box sx={{ position: 'absolute', right: '10px', top: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={CloseDrower}>
//           <CloseOutlined />
//         </Box>
//       </Typography>
//       <Divider />
//       <List>
//         <ListItem>
//           <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
//             <FormControl fullWidth>
//               <label>Status Filter</label>
//               <Select fullWidth sx={{ width: '100%' }} value={statusfilter} onChange={handleChangeStatus}>
//                 <MenuItem value={true}>Active</MenuItem>
//                 <MenuItem value={false}>Inactive</MenuItem>
//               </Select>
//             </FormControl>
//           </ListItemIcon>
//           <ListItemText />
//         </ListItem>

//         <Divider />

//         <ListItem>
//           <ListItemIcon sx={{ width: '100%', mt: 4, gap: 4 }}>
//             <Button variant="contained" color="secondary" onClick={CloseDrower}>
//               Cancel
//             </Button>
//             <Button variant="contained" onClick={filterSearch}>
//               Submit
//             </Button>
//           </ListItemIcon>
//           <ListItemText />
//         </ListItem>
//       </List>
//     </Box>
//   );

//   return (
//     <>
//       <Box>
//         {/* filter and reset filter */}

//         {/* <Box
//           sx={{
//             display: 'flex',
//             maxWidth: '100%',
//             justifyContent: 'space-between'
//           }}
//         >
//           <TextField
//             fullWidth
//             label="Search By Title"
//             id="search"
//             value={searchQuery}
//             sx={{ width: 400 }}
//             onChange={(e) => {
//               setCurrentPage(1);
//               setSearchQuery(e.target.value);
//               debouncedHandleSearch(e.target.value);
//             }}
//           />
//           <div>
//             <Box sx={{ display: 'flex', gap: '10px' }}>
//               <Button onClick={toggleDrawer(true)} variant="contained">
//                 {' '}
//                 <FilterOutlined />
//                 &nbsp; Fillter
//               </Button>
//               <Button onClick={clearFilter} variant="contained">
//                 {' '}
//                 <ReloadOutlined /> &nbsp; Reset
//               </Button>
//             </Box>

//             <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor="right">
//               {DrawerList}
//             </Drawer>
//           </div>
//         </Box> */}

//         {/* Tabledata */}
//         <TableContainer
//           sx={{
//             width: '100%',
//             overflowX: 'auto',
//             position: 'relative',
//             display: 'block',
//             maxWidth: '100%',
//             '& td, & th': { whiteSpace: 'nowrap' }
//           }}
//         >
//           <Table
//             aria-labelledby="tableTitle"
//             sx={{
//               '& .MuiTableCell-root:first-of-type': {
//                 pl: 2
//               },
//               '& .MuiTableCell-root:last-of-type': {
//                 pr: 3
//               }
//             }}
//           >
//             <OrderTableHead order={order} orderBy={orderBy} />
//             <TableBody>
//               {userList?.map((row, index) => {
//                 const isItemSelected = isSelected(row.trackingNo);
//                 const labelId = `enhanced-table-checkbox-${index}`;

//                 return (
//                   <TableRow
//                     hover
//                     role="checkbox"
//                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//                     aria-checked={isItemSelected}
//                     tabIndex={0}
//                     key={row.index}
//                     selected={isItemSelected}
//                   >
//                     <TableCell id={labelId} scope="row" align="center">
//                       <Link color="secondary" component={RouterLink} to="">
//                         {index + 1}
//                       </Link>
//                     </TableCell>

//                     <TableCell align="center">{row.key}</TableCell>
//                     <TableCell align="center">{row.value}</TableCell>

//                     <TableCell
//                       align="center"
//                       sx={{
//                         cursor: 'pointer'
//                       }}
//                     >
// <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '2px' }}>
//   <span>
//     <EditOutlined onClick={() => Modalopen(row)} />
//   </span>
// </Box>
//                     </TableCell>
//                   </TableRow>
//                 );
//               })}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         {/* Tabledata */}

//         {/* Pagination */}
//         {/* <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 4, borderTop: '1px solid #ddd', pt: 4, justifyContent: 'end' }}>
//           <Button variant="contained" disabled={currentPage === 1} onClick={handlePrevPage}>
//             Previous
//           </Button>
//           <Typography>
//             Page {currentPage} of {totalPages}
//           </Typography>
//           <Button variant="contained" disabled={currentPage === totalPages} onClick={handleNextPage}>
//             Next
//           </Button>
//         </Stack> */}
//         {/* Pagination */}
//       </Box>

//       <AddConfiguration fetchData={fetchData} modalopens={openAddModal} setModalopens={setOpenAddModal} editData={editData} />

//       <EditConfiguration fetchData={fetchData} modalopens={modalopens} setModalopens={setModalopens} editData={editData} />
//       <Loading loading={loading} />
//     </>
//   );
// };
// export default Configuration;
