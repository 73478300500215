import React, { useState, useEffect } from 'react';
import MainCard from 'components/MainCard';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
import DietPlan from './DietPlan';
import { Box, Button, Typography } from '@mui/material';
import XLSX, { utils, writeFile } from 'xlsx';
import DietExcelSheet from './DietExcelSheet';

const DietPlanList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const ExcelModal = () => {
    setModalOpen(true);
  };
  const sheetData = [
    {
      days: ' Monday',
      time: 'morning',
      mealinclude: 'Tea,Coffie',
      mealexclude: 'Oily stuffs,fish'
    },
    {
      days: ' ',
      time: 'evening',
      mealinclude: 'dal,rice'
    },
    {
      days: ' Tuesday',
      time: 'morning',
      mealinclude: 'Tea,Coffie',
      mealexclude: 'Oily stuffs,fish'
    },
    {
      days: ' ',
      time: 'evening',
      mealinclude: 'dal,rice'
    }
  ];
  const handleTemplateDownload = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(sheetData);
    utils.book_append_sheet(wb, ws, 'MySheet');
    writeFile(wb, 'DietPlan.xlsx');
  };

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="" setOpenAddModal={setOpenAddModal}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
            <Typography sx={{ fontSize: 20 }} gutterBottom>
              User Diet Plans
            </Typography>
            <Box>
              <Button sx={{ mr: 2, fontSize: '12px' }} onClick={() => handleTemplateDownload()}>
                Download Example Excel
              </Button>
              <Button variant="contained" onClick={ExcelModal}>
                Upload Excel
              </Button>
            </Box>
          </Box>

          <DietPlan openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
          <DietExcelSheet modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default DietPlanList;
