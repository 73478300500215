import React, { useState } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import usePost from 'hooks/usePost';
import { toast } from 'react-toastify';
import Loading from 'pages/Loader/Loading';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddTransformationData = ({ setModalopens, modalopens, fetchData }) => {
  const { mutateAsync: AddPlan } = usePost();
  const [shortDescLength, setShortDescLength] = useState(0);
  const [longDescLength, setLongDescLength] = useState(0);
  const [transformationData, setTransformationData] = useState({
    title: '',
    shortDesc: '',
    externalLink: '',
    longDesc: '',
    image: null,
    imagePreview: null
  });
  const [errors, setErrors] = useState({
    title: false,
    longDesc: false,
    image: false,
    externalLink: false
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransformationData((prevState) => ({
      ...prevState,
      // [name]: name === 'longDesc' ? value.replace(/(\r\n|\n|\r)/gm, '') : value
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
    if (name === 'shortDesc') {
      setShortDescLength(value.length);
    }
    if (name === 'longDesc') {
      setLongDescLength(value.replace(/(\r\n|\n|\r)/gm, '').length);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTransformationData((prevState) => ({
          ...prevState,
          image: file,
          imagePreview: reader.result // Set image preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    const errors = {};
    if (!transformationData.title.trim()) {
      errors.title = true;
    }
    if (!transformationData.externalLink.trim()) {
      errors.externalLink = true;
    }

    if (!transformationData.longDesc.trim()) {
      errors.longDesc = true;
    }
    if (transformationData.image === null) {
      errors.image = true;
    }

    if (
      !transformationData.longDesc.trim() ||
      !transformationData.externalLink.trim() ||
      !transformationData.title.trim() ||
      transformationData.image === null
    ) {
      setLoading(false);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const formData = new FormData();
    formData.append('title', transformationData.title);
    formData.append('externalLink', transformationData.externalLink);
    formData.append('shortDesc', transformationData.shortDesc);
    formData.append('longDesc', transformationData.longDesc);
    formData.append('image', transformationData.image);

    AddPlan({
      url: 'transformation',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Transformation Successfully Added', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
        setTransformationData({
          title: '',
          shortDesc: '',
          longDesc: '',
          image: null,
          imagePreview: null
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />

        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Transformation
          <IconButton
            aria-label="close"
            onClick={() => setModalopens(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Title</InputLabel>
                <OutlinedInput
                  id="transfomrmationTitle"
                  type="text"
                  name="title"
                  placeholder="Enter Title"
                  fullWidth
                  value={transformationData.recipeTitle}
                  onChange={handleChange}
                />
                {errors.title && (
                  <Typography variant="caption" color="error">
                    Title is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">Add External Link</InputLabel>
                <OutlinedInput
                  id="externalLink"
                  type="text"
                  name="externalLink"
                  placeholder="Enter Link"
                  fullWidth
                  value={transformationData.externalLink}
                  onChange={handleChange}
                />
                {errors.externalLink && (
                  <Typography variant="caption" color="error">
                    Link is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Short Description</InputLabel>
                <textarea
                  id="transfomrmationTitle"
                  type="text"
                  name="shortDesc"
                  placeholder="Short Description in 100 Words"
                  fullWidth
                  value={transformationData.shortDesc}
                  onChange={handleChange}
                  style={{ padding: '10px' }}
                  // maxlength="100"
                />
                {/* <Typography variant="body2" color="textSecondary">
                  {shortDescLength}/100 characters
                </Typography> */}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Long Description</InputLabel>
                <textarea
                  id="transfomrmationTitle"
                  type="text"
                  name="longDesc"
                  placeholder="Long Description in 1000 Charecters"
                  fullWidth
                  value={transformationData.longDesc}
                  onChange={handleChange}
                  style={{ padding: '10px' }}
                  maxlength="1000"
                />
                <Typography variant="body2" color="textSecondary">
                  {longDescLength}/1000 characters
                </Typography>
                {errors.longDesc && (
                  <Typography variant="caption" color="error">
                    Long Description is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '27px'
                  }}
                  htmlFor="input-file"
                >
                  Select an image
                </label>
                {transformationData.imagePreview && (
                  <img
                    src={transformationData.imagePreview}
                    alt="Preview"
                    style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                  />
                )}

                {!transformationData.imagePreview && errors.image && (
                  <Typography variant="caption" color="error">
                    Image is required
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Add Transformation
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default AddTransformationData;
