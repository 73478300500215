//users Table Columns
export const HeadCells = [
  { id: 'S.No', align: 'left', disablePadding: false, label: 'S. No.' },
  { id: 'name', align: 'left', disablePadding: false, label: 'Name' },
  { id: 'mobileNumber', align: 'left', disablePadding: false, label: 'Phone' },
  { id: 'gender', align: 'left', disablePadding: false, label: 'Gender' },
  { id: 'plan', align: 'left', disablePadding: false, label: 'Plan' },
  { id: 'planExpire', align: 'left', disablePadding: false, label: 'Plan Expire' },
  { id: 'assignto', align: 'left', disablePadding: false, label: 'Assign Dietitian' },
  { id: 'isCompleted', align: 'left', disablePadding: false, label: 'Profile Completed' },
  { id: 'isActive', align: 'left', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'left', disablePadding: false, label: 'Action' }
];

export const HeadCellsDaititian = [
  { id: 'S.No', align: 'left', disablePadding: false, label: 'S. No.' },
  { id: 'name', align: 'left', disablePadding: false, label: 'Name' },
  { id: 'mobileNumber', align: 'left', disablePadding: false, label: 'Phone' },
  { id: 'gender', align: 'left', disablePadding: false, label: 'Gender' },
  { id: 'plan', align: 'left', disablePadding: false, label: 'Plan' },
  { id: 'planExpire', align: 'left', disablePadding: false, label: 'Plan Expire' },
  // { id: 'assignto', align: 'left', disablePadding: false, label: 'Assign Dietitian' },
  { id: 'isCompleted', align: 'left', disablePadding: false, label: 'Profile Completed' },
  { id: 'isActive', align: 'left', disablePadding: false, label: 'Status' }
];

// Transformation Table columns
export const TransformationColumn = [
  { id: 'S.No', align: 'center', disablePadding: false, label: 'S. No.' },
  { id: 'title', align: 'center', disablePadding: false, label: 'Title' },
  { id: 'externalLink', align: 'center', disablePadding: false, label: 'External Link' },
  { id: 'shortDesc', align: 'center', disablePadding: false, label: 'Short Description' },
  { id: 'longDesc', align: 'center', disablePadding: false, label: 'Long Description' },
  { id: 'isActive', align: 'right', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'right', disablePadding: false, label: 'Action' }
];

// Recipes Table columns
export const RecipesColumn = [
  { id: 'S.No', align: 'left', disablePadding: false, label: 'S. No.' },
  { id: 'title', align: 'left', disablePadding: false, label: 'Title' },
  { id: 'makingDuration', align: 'right', disablePadding: false, label: 'Cooking Duration' },
  { id: 'ingredients', align: 'right', disablePadding: false, label: 'Ingredients' },
  { id: 'likes', align: 'right', disablePadding: false, label: 'Likes' },
  { id: 'isActive', align: 'right', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'right', disablePadding: false, label: 'Action' }
];

//Dietician Table Columns
export const DieticianColumn = [
  { id: 'S.No', align: 'center', disablePadding: false, label: 'S. No.' },
  { id: 'name', align: 'center', disablePadding: false, label: 'Name' },
  { id: 'email', align: 'center', disablePadding: false, label: 'Email' },
  { id: 'mobileNumber', align: 'center', disablePadding: false, label: 'Mobile Number' },
  { id: 'status', align: 'right', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'right', disablePadding: false, label: 'Action' }
];

//Story Table Columns
export const StoriesColumn = [
  { id: 'S.No', align: 'center', disablePadding: false, label: 'S. No.' },
  { id: 'title', align: 'center', disablePadding: false, label: 'Title' },
  { id: 'libk', align: 'center', disablePadding: false, label: 'External Link' },
  { id: 'storyStartDate', align: 'center', disablePadding: false, label: 'Start Date' },
  { id: 'storyEndDate', align: 'center', disablePadding: false, label: 'End Date' },
  { id: 'status', align: 'right', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'right', disablePadding: false, label: 'Action' }
];

// diet plan column

export const DietPlans = [
  { id: 'S.No', align: 'left', disablePadding: false, label: 'S. No.' },
  { id: 'name', align: 'left', disablePadding: false, label: 'Name' },
  { id: 'mobileNumber', align: 'left', disablePadding: false, label: 'Phone' },
  { id: 'gender', align: 'left', disablePadding: false, label: 'Gender' },
  { id: 'plan', align: 'left', disablePadding: false, label: 'Plan' },
  { id: 'planExpire', align: 'left', disablePadding: false, label: 'Plan Expire' },
  { id: 'isActive', align: 'left', disablePadding: false, label: 'Status' },
  { id: 'Action', align: 'left', disablePadding: false, label: 'Action' }
];

// configuration Table columns
export const ConfigurationColumn = [
  { id: 'S.No', align: 'center', disablePadding: false, label: 'S. No.' },
  { id: 'key', align: 'center', disablePadding: false, label: 'Key' },
  { id: 'value', align: 'center', disablePadding: false, label: 'Value' },
  { id: 'Action', align: 'right', disablePadding: false, label: 'Action' }
];
