import React from 'react';
import ComponentSkeleton from '../components-overview/ComponentSkeleton';
import MainCard from 'components/MainCard';
import Transformation from './Transformation';
const TransformationList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="Transformations" setOpenAddModal={setOpenAddModal}>
          <Transformation openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default TransformationList;
