import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
const override = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red'
};

const Loading = ({ loading }) => {
  return (
    <div
      className="sweet-loading"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        position: 'fixed',
        top: '0px',
        left: '50%'
      }}
    >
      {' '}
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <BeatLoader color={'blue'} loading={loading} cssOverride={override} size={30} aria-label="Loading Spinner" data-testid="loader" />
      </div>
    </div>
  );
};

export default Loading;
