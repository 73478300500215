import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePatch from 'hooks/usePatch';
import { toast } from 'react-toastify'; // Import toast for notifications
import Loading from 'pages/Loader/Loading';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function EditTransformationData({ setModalopens, modalopens, fetchData, editData }) {
  const { mutateAsync: EditPlan } = usePatch();
  const [imagePreview, setImagePreview] = useState(null);
  const defaultUserData = {
    id: '',
    title: '',
    externalLink: '',
    shortDesc: '',
    longDesc: '',
    isActive: false,
    image: null
  };
  const [shortDescLength, setShortDescLength] = useState(0);
  const [longDescLength, setLongDescLength] = useState(0);

  const [userData, setUserData] = useState(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  const [loading, setLoading] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [longDescError, setLongDescError] = useState('');
  const [imageError, setImageError] = useState('');
  useEffect(() => {
    setUserData(editData ? { ...defaultUserData, ...editData } : defaultUserData);
    if (editData?.shortDesc) {
      setShortDescLength(editData.shortDesc.length);
    } else {
      setShortDescLength(0);
    }

    if (editData?.longDesc) {
      setLongDescLength(editData.longDesc.length);
    } else {
      setLongDescLength(0);
    }
  }, [editData]);

  const handleClose = () => {
    setModalopens(false);
    setImagePreview(null);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setUserData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    if (name === 'shortDesc') {
      setShortDescLength(value.length);
    }
    if (name === 'longDesc') {
      setLongDescLength(value.length);
    }
  };

  const submitForm = () => {
    let valid = true;

    if (!userData.title) {
      setTitleError('Title is required');
      valid = false;
    } else {
      setTitleError('');
    }

    if (!userData.longDesc) {
      setLongDescError('Long Description is required');
      valid = false;
    } else {
      setLongDescError('');
    }

    if (userData?.image === 'null') {
      setImageError('Image is required');
      valid = false;
    } else {
      setImageError('');
    }

    if (!valid) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('_id', userData?._id);
    formData.append('title', userData?.title);
    formData.append('externalLink', userData?.externalLink);
    formData.append('shortDesc', userData?.shortDesc);
    formData.append('longDesc', userData?.longDesc);
    formData.append('isActive', userData?.isActive);
    formData.append('image', userData?.image);

    EditPlan({
      url: 'transformation',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Transformation Successfully Updated', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
        setModalopens(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // Handle file input change

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);

      setUserData((prevData) => ({
        ...prevData,
        image: file
      }));
      setImageError('');
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Edit Transformation
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="planName">Recipe Title</InputLabel>
                <OutlinedInput
                  id="planName"
                  type="text"
                  name="title"
                  placeholder="Recipe Title"
                  fullWidth
                  defaltValue={userData?.title}
                  value={userData?.title}
                  onChange={handleChange}
                />
                {titleError && (
                  <Typography variant="body2" color="error">
                    {titleError}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="planName">External Link</InputLabel>
                <OutlinedInput
                  id="planName"
                  type="text"
                  name="externalLink"
                  placeholder="Enter Link"
                  fullWidth
                  defaltValue={userData?.externalLink}
                  value={userData?.externalLink}
                  onChange={handleChange}
                />
                {titleError && (
                  <Typography variant="body2" color="error">
                    {titleError}
                  </Typography>
                )}
              </Stack>
            </Grid>

            {/* making duration */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="durationInMonth">Short Description</InputLabel>
                <textarea
                  id="durationInMonth"
                  type="text"
                  name="shortDesc"
                  placeholder="Short Description in 100 Charecters"
                  fullWidth
                  defaltValue={userData?.shortDesc}
                  value={userData.shortDesc}
                  onChange={handleChange}
                  maxLength="100"
                />
                <Typography variant="body2" color="textSecondary">
                  {shortDescLength}/100 characters
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="durationInMonth">Long Description</InputLabel>
                <textarea
                  id="durationInMonth"
                  type="text"
                  maxlength="1000"
                  name="longDesc"
                  placeholder="Long Description in 1000 Charecters"
                  fullWidth
                  defaltValue={userData?.longDesc}
                  value={userData.longDesc}
                  onChange={handleChange}
                />
                <Typography variant="body2" color="textSecondary">
                  {longDescLength}/1000 characters
                </Typography>
                {longDescError && (
                  <Typography variant="body2" color="error">
                    {longDescError}
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* isactive */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="isActive">Status</InputLabel>
                <Select labelId="isActive-label" id="isActive" value={userData.isActive} onChange={handleChange} fullWidth name="isActive">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '-5px'
                  }}
                  htmlFor="input-file"
                >
                  Select A Image
                </label>
                {imageError && (
                  <Typography variant="body2" color="error">
                    {imageError}
                  </Typography>
                )}
                {!imagePreview && (
                  <img
                    src={userData?.image}
                    crossOrigin="anonymous"
                    alt="Preview"
                    style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                  />
                )}
                {imagePreview && (
                  <>
                    <img src={imagePreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submitForm} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
