import { CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Grid, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import useGet from '../../hooks/useGet';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const DetailDietPlan = ({ setModalopens, modalopens, planList }) => {
  const [selectedIndex, setSelectedIndex] = useState(0); // track the clicked day index
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0); // track the clicked plan index

  const handleClose = () => {
    setModalopens(false);
  };

  const handleSetIndex = (index) => {
    setSelectedIndex(index);
  };

  const handleSetplanIndex = (index) => {
    setSelectedPlanIndex(index);
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Diet Plan Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  {planList?.dietPlan?.map((i, index) => (
                    <button
                      key={index}
                      style={{
                        backgroundColor: index === selectedIndex ? '#1677ff' : 'transparent',
                        height: '40px',
                        width: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '25px',
                        border: index === selectedIndex ? 'none' : '1px solid gray',
                        color: index === selectedIndex ? 'white' : 'black', // Change text color based on selected state
                        transition: 'background-color 0.3s ease', // Add transition for smooth color change
                        fontSize: '15px'
                      }}
                      onClick={() => handleSetIndex(index)}
                    >
                      {i?.day}
                    </button>
                  ))}
                </div>
                <br />
                <h3>Meal Include :</h3>
                <div style={{ display: 'flex', border: '1px solid gray', padding: '5px', borderRadius: '5px' }}>
                  <div
                    style={{
                      width: '20%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      gap: '5px',
                      borderRight: '1px solid gray'
                    }}
                  >
                    {planList?.dietPlan &&
                      planList.dietPlan[selectedIndex]?.plans?.map((i, index) => (
                        <button
                          key={index}
                          style={{
                            height: '35px',
                            width: '85px',
                            backgroundColor: index === selectedPlanIndex ? '#1677ff' : 'transparent',
                            color: index === selectedPlanIndex ? 'white' : 'black',
                            border: index === selectedPlanIndex ? 'none' : '1px solid gray',
                            transition: 'background-color 0.3s ease',
                            borderRadius: '25px',
                            fontSize: '15px'
                          }}
                          onClick={() => handleSetplanIndex(index)}
                        >
                          {i?.time}
                        </button>
                      ))}
                  </div>

                  <div
                    style={{
                      width: '80%'
                    }}
                  >
                    <ol style={{ marginTop: '0px', fontSize: '16px' }}>
                      {planList?.dietPlan &&
                        planList.dietPlan[selectedIndex]?.plans &&
                        planList.dietPlan[selectedIndex].plans[selectedPlanIndex]?.mealIncluded?.map((i, index) => (
                          <li
                            key={index}
                            style={{
                              border: 'none',
                              transition: 'background-color 0.3s ease' // Add transition for smooth color change
                            }}
                            onClick={() => handleSetplanIndex(index)}
                          >
                            {i}
                          </li>
                        ))}
                    </ol>
                  </div>
                </div>
                <br />
                <h3>Meal Exclude :</h3>
                <div style={{ display: 'flex', border: '1px solid gray', padding: '5px', borderRadius: '5px' }}>
                  <div
                    style={{
                      width: '100%'
                    }}
                  >
                    <ol style={{ marginTop: '5px', fontSize: '16px' }}>
                      {planList?.dietPlan && planList.dietPlan[selectedIndex]?.plans[0]?.mealExcluded?.map((i) => <li>{i}</li>)}
                    </ol>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default DetailDietPlan;
