import { useState, useEffect } from 'react';
import { Box, Button, TableContainer, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import useGet from 'hooks/useGet';
import Loading from 'pages/Loader/Loading';
import { AllUserlist } from '../../constants/api';
import AddFollowUp from './AddFollowUp';

const Followup = () => {
  const [membershipList, setMembershipList] = useState();
  const { mutateAsync: MembershipList } = useGet();
  const [loading, setLoading] = useState(false);
  const [addmodalopens, setAddModalopens] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await MembershipList({
        url: `${AllUserlist}?page=${currentPage}&limit=${itemsPerPage}`,
        type: 'details',
        token: true
      });
      setMembershipList(res);
      setTotalPages(res?.meta?.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage]);

  const MemebershipAdd = () => {
    setAddModalopens(true);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '-65px' }}>
        <Typography sx={{ fontSize: 18, textAlign: 'center', color: '#000', textTransform: 'capitalize', mb: 0 }} gutterBottom>
          {/* Follow Up */}
        </Typography>

        <Button variant="contained" onClick={() => MemebershipAdd()} sx={{ float: 'right' }}>
          Add Follow Up
        </Button>
      </Box>

      <Box>
        <TableContainer
          sx={{
            width: '100%',
            overflowX: 'auto',
            position: 'relative',
            display: 'block',
            maxWidth: '100%',
            '& td, & th': { whiteSpace: 'nowrap' }
          }}
        >
          {membershipList?.items?.map((row, index) => {
            return (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '115px', display: 'flex', justifyContent: 'start' }}>
                  <h3 style={{ width: '115px' }}>{row.name}: </h3>
                </Box>
                <Box
                  sx={{
                    marginLeft: '10px',
                    marginTop: '22px',
                    width: '100%',
                    fontSize: '16px',
                    border: '1px solid #000',
                    borderRadius: '2px',
                    minHeight: '75px',
                    overflow: 'auto',
                    maxHeight: '108px',
                    height: '100%'
                  }}
                >
                  <ol style={{ margin: '4px 0' }}>
                    {row?.followUp?.map((i) => (
                      <>
                        {' '}
                        <li>{i?.freeText}</li>
                      </>
                    ))}
                  </ol>
                </Box>
                <Box sx={{ marginLeft: '10px', marginTop: '22px' }}>
                  <span style={{ fontSize: '20px' }}>{/* <EditOutlined /> */}</span>
                </Box>
              </Box>
            );
          })}
        </TableContainer>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 4, borderTop: '1px solid #ddd', pt: 4, justifyContent: 'end' }}>
          <Button variant="contained" disabled={currentPage === 1} onClick={handlePrevPage}>
            Previous
          </Button>
          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button variant="contained" disabled={currentPage === totalPages} onClick={handleNextPage}>
            Next
          </Button>
        </Stack>
      </Box>
      <AddFollowUp addmodalopens={addmodalopens} setAddModalopens={setAddModalopens} fetchdata={fetchData} />

      <Loading loading={loading} />
    </>
  );
};
export default Followup;
