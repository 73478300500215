import React, { useState } from 'react';
import { Button, Grid, Stack, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined } from '@ant-design/icons';
import usePost from 'hooks/usePost';
import { toast } from 'react-toastify';
import Loading from 'pages/Loader/Loading';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddDieticianData = ({ setModalopens, modalopens, fetchData }) => {
  const { mutateAsync: AddPlan } = usePost();
  const [dietcianData, setDietcianData] = useState({
    name: '',
    email: '',
    password: '',
    mobileNumber: ''
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
    mobileNumber: false
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobileNumber') {
      const mobileNumber = value.replace(/\D/g, '');
      const limitedMobileNumber = mobileNumber.slice(0, 10);
      setDietcianData((prevState) => ({
        ...prevState,
        [name]: limitedMobileNumber
      }));
    } else {
      setDietcianData((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const errors = {};
    if (!dietcianData.name.trim()) {
      errors.name = true;
    }
    if (!dietcianData.email.trim()) {
      errors.email = true;
    }
    if (!dietcianData.password.trim()) {
      errors.password = true;
    }
    if (!dietcianData.mobileNumber.trim()) {
      errors.mobileNumber = true;
    }
    if (!dietcianData.name.trim() || !dietcianData.email.trim() || !dietcianData.password.trim() || !dietcianData.mobileNumber.trim()) {
      setLoading(false);
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    AddPlan({
      url: 'admin',
      type: 'details',
      payload: dietcianData,
      // file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Dietician Successfully Added', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
        setDietcianData({
          name: '',
          email: '',
          password: '',
          mobileNumber: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />

        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Dietician
          <IconButton
            aria-label="close"
            onClick={() => setModalopens(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Name</InputLabel>
                <OutlinedInput
                  id="transfomrmationTitle"
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  fullWidth
                  value={dietcianData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <Typography variant="caption" color="error">
                    Name is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Email</InputLabel>
                <OutlinedInput
                  id="transfomrmationTitle"
                  type="text"
                  name="email"
                  placeholder="Enter Email Address"
                  fullWidth
                  value={dietcianData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <Typography variant="caption" color="error">
                    Email is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Mobile Number</InputLabel>
                <OutlinedInput
                  id="transfomrmationTitle"
                  type="text"
                  name="mobileNumber"
                  placeholder="Enter 10 digit Mobile Number"
                  fullWidth
                  value={dietcianData.mobileNumber}
                  onChange={handleChange}
                />
                {errors.mobileNumber && (
                  <Typography variant="caption" color="error">
                    Mobille Number is required
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="transfomrmationTitle">Password</InputLabel>
                <OutlinedInput
                  id="transfomrmationTitle"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  fullWidth
                  value={dietcianData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <Typography variant="caption" color="error">
                    Password is required
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Add Dietician
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default AddDieticianData;
