import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify'; // Import toast for notifications
import { BookingStatus, MembershipPlan, PlanActive, UserUpdate } from 'constants/api';
import usePatch from 'hooks/usePatch';
import Loading from 'pages/Loader/Loading';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));
function BookingStatusModal({ openmodal, setOpenmodal, data, fetchdata }) {
  const [userData, setUserData] = useState(data);
  const [loading, setLoading] = useState(false);
  const { mutateAsync: EditUser } = usePatch();
  const [connectDate, setConnectDate] = useState();

  useEffect(() => {
    setUserData(data);
  }, [data]);

  const handleClose = () => {
    setUserData('');
    setOpenmodal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const submitForm = () => {
    setLoading(true);
    const payload = {
      _id: userData?._id,
      status: userData.bookingStatus,
      dietPlanStatus: userData.dietPlanStatus,
      addDate: connectDate
    };

    EditUser({
      url: BookingStatus,
      type: 'details',
      payload: payload,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Successfully Updated', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setUserData('');
        setOpenmodal(false);
        fetchdata();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={openmodal}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Counselling Booking
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="user-name">Name</InputLabel>
                <OutlinedInput
                  id="user-name"
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  fullWidth
                  value={userData?.users?.name}
                  onChange={handleChange}
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="user-mobileNumber">Mobile Number</InputLabel>
                <OutlinedInput
                  id="user-mobileNumber"
                  type="text"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  fullWidth
                  value={userData?.users?.mobileNumber}
                  onChange={handleChange}
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="timeSlot">Time Slot</InputLabel>
                <OutlinedInput
                  id="user-timeSlot"
                  type="text"
                  name="timeSlot"
                  placeholder="Time Slot"
                  fullWidth
                  value={userData?.timeSlot}
                  onChange={handleChange}
                  disabled
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bookignStatus">Booking Status</InputLabel>
                <Select
                  labelId="bookingStatus-label"
                  id="bookingStatus"
                  value={userData?.status}
                  onChange={handleChange}
                  fullWidth
                  name="bookingStatus"
                  defaultValue={userData?.status || data?.status}
                >
                  <MenuItem value="Converted">Converted</MenuItem>
                  <MenuItem value="Interested">Interested</MenuItem>
                  <MenuItem value="Not Picked Up">Not Picked Up</MenuItem>
                  <MenuItem value="Unreachable">Unreachable</MenuItem>
                  <MenuItem value="Call Back">Call Back</MenuItem>
                  <MenuItem value="Not Interested">Not Interested</MenuItem>
                </Select>
              </Stack>
            </Grid>

            {/*  */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <div className="date-range-select">
                  <InputLabel htmlFor="externalLink">Connect Date</InputLabel>
                  <ReactDatePicker
                    className="pppppp"
                    style={{ width: '100%', height: '41px', border: '1px solid #d9d9d9', borderRadius: '3px' }}
                    selected={connectDate || data?.addDate}
                    onChange={(date) => {
                      setConnectDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText={'Select Connect Date'}
                    minDate={new Date()}
                  />
                </div>
              </Stack>
            </Grid>
            {/*  */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="dietPlanStatus">Diet Plan Status</InputLabel>
                <Select
                  labelId="dietPlanStatus-label"
                  id="dietPlanStatus"
                  value={userData?.dietPlanStatus}
                  onChange={handleChange}
                  fullWidth
                  name="dietPlanStatus"
                  defaultValue={userData?.dietPlanStatus || data?.dietPlanStatus}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Started">Started </MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={submitForm}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

export default BookingStatusModal;
