import React, { useState, useEffect } from 'react';
import ComponentSkeleton from '../components-overview/ComponentSkeleton';
import MainCard from 'components/MainCard';
import { Card, Box, Avatar, Typography, Grid, CardContent, Divider } from '@mui/material';
import { PhoneOutlined, RightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useGet from 'hooks/useGet';
import { UserAllDeatils } from 'constants/api';
import moment from 'moment';
const tableStyles = {
  fontFamily: 'arial, sans-serif',
  borderCollapse: 'collapse',
  width: '100%',
  '& td, & th': {
    border: '1px solid #dddddd',
    textAlign: 'center',
    padding: '8px'
  },
  '& tr:nth-of-type(even)': {
    backgroundColor: '#dddddd'
  }
};

function UserProfile() {
  const params = useParams();
  const { mutateAsync: userdetails } = useGet();
  const [userFullDetails, setUserFullDetails] = useState();

  const fetchdata = () => {
    userdetails({
      url: `${UserAllDeatils}${params?.id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setUserFullDetails(res);
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <div>
      <ComponentSkeleton>
        <MainCard>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <div>
                <h1>{'User Profile'}</h1>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '20PX' }}>
                <RightOutlined />
              </div>
              <div>
                <h1>
                  {userFullDetails?.name
                    ? userFullDetails.name
                        .split(' ')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                    : ''}
                </h1>
              </div>
            </div>
            <div>{userFullDetails?.isActive === true ? 'Active' : 'In-Active'}</div>
          </div>

          <Box>
            <Card sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ p: 4, borderRight: '1px solid #ddd' }}>
                  {userFullDetails?.profilePic ? (
                    <>
                      <img
                        src={userFullDetails?.profilePic}
                        width="150px"
                        height="150px"
                        style={{ borderRadius: '50%' }}
                        crossOrigin="anonymous"
                        alt="user-image"
                      />
                    </>
                  ) : (
                    <>
                      <Avatar
                        sx={{
                          color: 'success.main',
                          bgcolor: 'success.lighter',
                          width: '150px',
                          height: '150px'
                        }}
                      ></Avatar>
                    </>
                  )}

                  <Typography variant="h4" sx={{ textAlign: 'center', pt: 2, textTransform: 'capitalize' }}>
                    {userFullDetails?.name}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          color: 'success.main',
                          bgcolor: 'success.lighter'
                        }}
                      >
                        <PhoneOutlined />
                      </Avatar>{' '}
                      {userFullDetails?.mobileNumber}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ width: '100%', p: 2 }}>
                    <Typography variant="h4">General Info</Typography>
                    <Box>
                      <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Gender:
                            </Typography>
                            <Typography variant="h6"> {userFullDetails?.gender}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Age:
                            </Typography>
                            <Typography variant="h6">
                              {' '}
                              {userFullDetails?.age} <sub>year</sub>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Number:
                            </Typography>
                            <Typography variant="h6">{userFullDetails?.mobileNumber || '--'}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ width: '100%', p: 2 }}>
                    <Typography variant="h4">More Info</Typography>
                    <Box>
                      <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Location : {userFullDetails?.address || 'Not Available'}
                            </Typography>
                            <Typography variant="h6">{userFullDetails?.address}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Device Type:
                            </Typography>
                            <Typography variant="h6">{userFullDetails?.deviceType}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Profile Completed:
                            </Typography>
                            <Typography variant="h6">{userFullDetails?.isCompletedStep == 1 ? ' Completed ' : 'Not Completed'}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h4">Health </Typography>
                    <Box>
                      <Grid container spacing={2} sx={{ pt: 2 }}>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Health Problem :{' '}
                            </Typography>
                            <Typography variant="h6">
                              {/* {userFullDetails?.userInfo?.healthProblem == true ? ' Some Issue' : 'No Problem'} */}
                              {userFullDetails?.userInfo?.healthCondition ? (
                                <>
                                  {userFullDetails?.userInfo?.healthCondition?.map((data) => (
                                    <Typography variant="h6" sx={{ textTransform: 'capitalize' }} color="text.secondary">
                                      {data}
                                    </Typography>
                                  ))}
                                </>
                              ) : (
                                '--'
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Height:
                            </Typography>
                            <Typography variant="h6">{userFullDetails?.userInfo?.height || '--'}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ display: 'flex', gap: '15px' }}>
                            <Typography variant="h6" color="textSecondary">
                              Weight:
                            </Typography>
                            <Typography variant="h6">
                              {userFullDetails?.userInfo?.weight || '--'} <sub>kg</sub>
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Card>
            <Box sx={{ display: 'flex', gap: '20px 1%', flexWrap: 'wrap' }}>
              {userFullDetails?.bmi && (
                <Card variant="outlined" sx={{ width: '100%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        BMI
                      </Typography>

                      <Box component="table" sx={tableStyles}>
                        <thead>
                          <tr>
                            <th>Height</th>
                            <th>Weight</th>
                            <th>Age</th>
                            <th>Created At</th>
                          </tr>
                        </thead>
                        {userFullDetails?.bmi?.map((item) => (
                          <tbody>
                            <tr>
                              <td>{item?.height}</td>
                              <td>{item?.weight}</td>
                              <td>{item?.age}</td>
                              <td>{item?.createdAt}</td>
                            </tr>
                          </tbody>
                        ))}
                      </Box>
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
              {userFullDetails?.dietitan && (
                <Card variant="outlined" sx={{ width: '30%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        Assign Dietitian
                      </Typography>

                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Dietitian Name:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {' '}
                          {userFullDetails?.dietitan?.name || '---'}
                        </Typography>
                      </Box>
                      <Divider />

                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Dietitian Phone:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {' '}
                          {userFullDetails?.dietitan?.mobileNumber || '---'}
                        </Typography>
                      </Box>
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
              {userFullDetails?.userActivePlan && (
                <Card variant="outlined" sx={{ width: '30%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        Active Plans
                      </Typography>

                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Plan Name:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {' '}
                          {userFullDetails?.userActivePlan?.[0]?.subsCription?.planName || '---'}
                        </Typography>
                      </Box>
                      <Divider />

                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Plan Expiry Date:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {userFullDetails?.userActivePlan?.[0]?.expiredDate
                            ? moment(userFullDetails?.userActivePlan?.[0]?.expiredDate)?.format('DD-MMM-YYYY')
                            : '---'}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Payment Mode:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {' '}
                          {userFullDetails?.userActivePlan?.[0]?.paymentMode || '---'}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box sx={{ display: 'flex', gap: '15px', justifyContent: 'space-between', py: 1 }}>
                        <Typography variant="h5" color="textSecondary">
                          Plan Duration:
                        </Typography>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                          {' '}
                          {userFullDetails?.userActivePlan?.[0]?.subsCription?.durationInMonth || '---'} <sub>month</sub>
                        </Typography>
                      </Box>
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
              {userFullDetails?.userInfo?.healthCondition && (
                <Card variant="outlined" sx={{ width: '30%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        Health Condition
                      </Typography>
                      {userFullDetails?.userInfo?.healthCondition?.map((data) => (
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }} color="text.secondary">
                          {data}
                        </Typography>
                      ))}
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
              {userFullDetails?.userInfo?.weightLose && (
                <Card variant="outlined" sx={{ width: '30%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        Current Weight Loss
                      </Typography>
                      {userFullDetails?.userInfo?.weightLose?.map((data) => (
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }} color="text.secondary">
                          {data}
                        </Typography>
                      ))}
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
              {userFullDetails?.userInfo?.previousWeightLose && (
                <Card variant="outlined" sx={{ width: '30%' }}>
                  <React.Fragment>
                    <CardContent>
                      <Typography variant="h4" sx={{ pb: 2 }} gutterBottom>
                        Previous Weight Loss
                      </Typography>
                      {userFullDetails?.userInfo?.previousWeightLose?.map((data) => (
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }} color="text.secondary">
                          {data}
                        </Typography>
                      ))}
                    </CardContent>
                  </React.Fragment>
                </Card>
              )}
            </Box>
          </Box>
        </MainCard>
      </ComponentSkeleton>
    </div>
  );
}

export default UserProfile;
