export const REFRESH_TOKEN = '/users/login-with-refresh-token';
// *********************admin login and password*******************
export const LOGINAPI = '/auth/login';
export const ForgotPassword = '/admin/forget-password';
export const ChangePassword = '/admin/update-password';
// *************************** user-data*****************

export const UserUpdate = '/users/';
export const Userget = '/users/info-id';
export const AllUserlist = '/users/list';
export const ExcelSheetupload = 'users/upload-excel';

// ***************** bookinglist
export const BookingListUrl = "booking/list";
export const BookingStatus = "booking";


// *****************************
export const UserAllDeatils = "/users/user-details/";
export const PlanActive = "/user-active-plan";
export const MembershipPlan = "/subscription-plan/list";
export const AddMembershipPlan = "/subscription-plan/";

//*****************Recipe***************
export const GetRecipe = 'recipe/list';
// ******************* notification***********
export const SendNotification = 'users/send-notification';
