import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined } from '@ant-design/icons';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const DetailRecipesData = ({ setModalopens, modalopens, editData }) => {
  const handleClose = () => {
    setModalopens(false);
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Recipe
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <CardHeader
            sx={{ textTransform: 'capitalize', px: 0 }}
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                <CardMedia
                  component="img"
                  height="100%"
                  image={editData?.recipeImage}
                  alt={editData?.recipeTitle}
                  crossOrigin="anonymous"
                />
              </Avatar>
            }
            title={`Recipe : ${editData?.recipeTitle}`}
            subheader={`Cooking Time: ${editData?.makingDuration}min`}
          />

          <Box sx={{ display: 'flex', pt: 1 }}>
            <Typography sx={{ fontSize: 14, width: '50%', textTransform: 'capitalize' }} gutterBottom>
              Recipe Name :
            </Typography>
            <Typography sx={{ fontSize: 14, width: '50%', textTransform: 'capitalize' }} gutterBottom>
              {editData?.recipeTitle}
            </Typography>
          </Box>
          <Divider />

          <Box sx={{ display: 'flex', pt: 1 }}>
            <Typography sx={{ fontSize: 14, width: '50%', textTransform: 'capitalize' }} gutterBottom>
              Cooking Direction :
            </Typography>
            <Box>
              {editData?.cookingDirection?.map((item) => (
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {' '}
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: 'flex', pt: 1 }}>
            <Typography sx={{ fontSize: 14, width: '50%', textTransform: 'capitalize' }} gutterBottom>
              ingredients :
            </Typography>

            <Box>
              {editData?.indredient?.map((item) => (
                <Typography sx={{ fontSize: 14 }} gutterBottom>
                  {' '}
                  {item}
                </Typography>
              ))}
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: 'flex', pt: 1 }}>
            <Typography sx={{ fontSize: 14, width: '50%', textTransform: 'capitalize' }} gutterBottom>
              Status :
            </Typography>
            <Typography sx={{ fontSize: 14, width: '50%' }} gutterBottom>
              {editData?.isActive === true ? 'Active' : 'Inctive'}
            </Typography>
          </Box>
          <Divider />
          <CardMedia
            component="img"
            image={editData?.recipeImage}
            alt={editData?.recipeTitle}
            crossOrigin="anonymous"
            style={{ height: '100px', width: '100px', objectFit: 'cover' }}
          />
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default DetailRecipesData;
