import React, { useState } from 'react';
import { Button, Grid, Stack, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined } from '@ant-design/icons';
import usePost from 'hooks/usePost';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import Loading from 'pages/Loader/Loading';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddStorieData = ({ setModalopens, modalopens, fetchData }) => {
  const { mutateAsync: AddPlan } = usePost();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [storieData, setStorieData] = useState({
    storiesTitle: '',
    externalLink: '',
    storyStartDate: '',
    storyEndDate: '',
    storyViewImg: null,
    storiesImage: null,
    imagePreview: null,
    viewImgPreview: null
  });
  const [errors, setErrors] = useState({
    storiesTitle: false,
    externalLink: false,
    storyStartDate: false,
    storyEndDate: false,
    storyViewImg: false,
    storiesImage: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStorieData((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
  };

  const handleStoriesImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setStorieData((prevState) => ({
          ...prevState,
          storiesImage: file,
          imagePreview: reader.result // Set image preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleViewImageChange = (e) => {
    // Function to handle the new image change
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setStorieData((prevState) => ({
          ...prevState,
          storyViewImg: file,
          viewImgPreview: reader.result // Set image preview for the new image
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = () => {
    setLoading(true);

    const errors = {};
    if (!storieData.storiesTitle.trim()) {
      errors.storiesTitle = true;
    }
    if (!storieData.externalLink.trim()) {
      errors.externalLink = true;
    }
    if (!startDate) {
      errors.storyStartDate = true;
    }
    if (!endDate) {
      errors.storyEndDate = true;
    }
    if (storieData.storyViewImg === null) {
      errors.storyViewImg = true;
    }

    if (storieData.storiesImage === null) {
      errors.storiesImage = true;
    }

    if (
      !storieData.storiesTitle.trim() ||
      !storieData.externalLink.trim() ||
      !startDate ||
      !endDate ||
      !storieData.storyViewImg === null ||
      !storieData.storiesImage === null
    ) {
      setLoading(false);
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const formData = new FormData();
    formData.append('storiesTitle', storieData.storiesTitle);
    formData.append('externalLink', storieData.externalLink);
    formData.append('storyStartDate', startDate);
    formData.append('storyEndDate', endDate);
    formData.append('storiesImage', storieData.storiesImage);
    formData.append('storyViewImg', storieData.storyViewImg);

    AddPlan({
      url: 'stories',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Story Added Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
        setStartDate('');
        setEndDate('');
        setStorieData({
          storiesTitle: '',
          externalLink: '',
          storyStartDate: '',
          storyEndDate: '',
          storyViewImg: null,
          storiesImage: null,
          imagePreview: null,
          viewImgPreview: null
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Story
          <IconButton
            aria-label="close"
            onClick={() => setModalopens(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="storieTitle">Title</InputLabel>
                <OutlinedInput
                  id="storieTitle"
                  type="text"
                  name="storiesTitle"
                  placeholder="Enter Title"
                  fullWidth
                  value={storieData.storiesTitle}
                  onChange={handleChange}
                />
                {errors.storiesTitle && (
                  <Typography variant="caption" color="error">
                    Title is required
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* link */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">Link</InputLabel>
                <OutlinedInput
                  id="externalLink"
                  type="url"
                  placeholder="https://example.com"
                  pattern="https://.*"
                  name="externalLink"
                  fullWidth
                  value={storieData.externalLink}
                  onChange={handleChange}
                />
                {errors.externalLink && (
                  <Typography variant="caption" color="error">
                    Link is required
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* start date */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <div className="date-range-select">
                  <InputLabel htmlFor="externalLink">Start Date</InputLabel>
                  <ReactDatePicker
                    className="pppppp"
                    style={{ width: '100%', height: '41px', border: '1px solid #d9d9d9', borderRadius: '3px' }}
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText={'Select Start Date'}
                  />
                </div>
                {errors.storyStartDate && (
                  <Typography variant="caption" color="error">
                    Start Date is required
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* end date */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <div className="date-range-select">
                  <InputLabel htmlFor="externalLink">End Date</InputLabel>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      // setEndDate(date.toISOString().split('T')[0]);
                      setEndDate(moment(date).format('YYYY-MM-DD'));
                    }}
                    minDate={startDate}
                    disabled={!startDate}
                    dateFormat="yyyy-MM-dd"
                    placeholderText={'Select End Date'}
                  />
                </div>
                {!startDate && (
                  <Typography variant="caption" color="error">
                    Select First Start Date Before End date.
                  </Typography>
                )}

                {errors.storyEndDate && (
                  <Typography variant="caption" color="error">
                    End Date is required
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/*Story image */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleStoriesImageChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9'
                  }}
                  htmlFor="input-file"
                >
                  Select Story Image
                </label>
                {storieData.imagePreview && (
                  <img src={storieData.imagePreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                )}
                {errors.storyViewImg && (
                  <Typography variant="caption" color="error">
                    View Image is required
                  </Typography>
                )}
              </Stack>
            </Grid>
            {/* New image input */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file-view" accept="image/*" onChange={handleViewImageChange} style={{ display: 'none' }} />
                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9'
                  }}
                  htmlFor="input-file-view"
                >
                  Select View Image
                </label>
                {storieData.viewImgPreview && (
                  <img src={storieData.viewImgPreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                )}
                {errors.storiesImage && (
                  <Typography variant="caption" color="error">
                    View Image is required
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Add Story
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default AddStorieData;
