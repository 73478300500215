// assets
import { DashboardOutlined, UserOutlined, WalletOutlined, BellOutlined } from '@ant-design/icons';

const Role = localStorage.getItem('role');

// icons
const icons = {
  DashboardOutlined,
  UserOutlined,
  BellOutlined,
  WalletOutlined
};

const dashboard = {
  id: 'group-dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
      role: ['1', '3']
    },
    {
      id: 'userTable',
      title: 'Users',
      type: 'item',
      url: '/user-list',
      icon: icons.UserOutlined,
      breadcrumbs: false,
      role: ['1', '3']
    },

    {
      id: 'Membership Plan',
      title: 'Membership Plan',
      type: 'item',
      url: '/membership-plan',
      icon: icons.UserOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Booking',
      title: 'Booking',
      type: 'item',
      url: '/booking',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Transformation',
      title: 'Transformation',
      type: 'item',
      url: '/transformation',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Recipes',
      title: 'Recipes',
      type: 'item',
      url: '/recipes',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Dietitian',
      title: 'Dietitian ',
      type: 'item',
      url: '/dietitian',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'DietPlan',
      title: 'Diet Plan',
      type: 'item',
      url: '/diet-plan',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['3']
    },
    {
      id: 'Story',
      title: 'Story',
      type: 'item',
      url: '/story',
      icon: icons.WalletOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Notification',
      title: 'Notification',
      type: 'item',
      url: '/notification',
      icon: icons.BellOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'Configuration',
      title: 'Configuration',
      type: 'item',
      url: '/configuration',
      icon: icons.BellOutlined,
      breadcrumbs: false,
      role: ['1']
    },
    {
      id: 'mealPicture',
      title: 'Meal Pictures',
      type: 'item',
      url: '/meal-pictures',
      icon: icons.UserOutlined,
      breadcrumbs: false,
      role: ['3']
    }
  ]
};

export default dashboard;
