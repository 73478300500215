import React, { useState } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import usePost from 'hooks/usePost';
import { toast } from 'react-toastify';
import Loading from 'pages/Loader/Loading';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddRecipesData = ({ setModalopens, modalopens, fetchData }) => {
  const { mutateAsync: AddPlan } = usePost();
  const [recipeData, setRecipeData] = useState({
    recipeTitle: '',
    makingDuration: '',
    recipeImage: null, // Set initial value to null
    imagePreview: null
  });
  const [ingredients, setIngredients] = useState(['']);
  const [cookingDirection, setCookingDirection] = useState(['']);
  const [titleError, setTitleError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [ingredientError, setIngredientError] = useState(false);
  const [directionError, setDirectionError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'recipeTitle' && value.trim() !== '') {
      setTitleError(false);
    }
    if (name === 'makingDuration' && value.trim() !== '') {
      const duration = parseInt(value, 10);

      if (duration > 240) {
        setDurationError(true);
        return;
      } else {
        setDurationError(false);
      }
    }

    setRecipeData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setRecipeData((prevState) => ({
          ...prevState,
          recipeImage: file,
          imagePreview: reader.result // Set image preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  // Add Ingredient
  const handleChanges = (e, index) => {
    const { value } = e.target;
    const updatedIngredients = [...ingredients];
    updatedIngredients[index] = value;
    setIngredients(updatedIngredients);
    if (value.trim() !== '') {
      setIngredientError(false);
    }
  };

  const addIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  const removeIngredient = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);

    setIngredientError(false);
  };
  // Add Ingredient
  // Add Cooking Direction
  const handleChangeDirection = (e, index) => {
    const { value } = e.target;
    const updatedDirections = [...cookingDirection];
    updatedDirections[index] = value;
    setCookingDirection(updatedDirections);
    if (value.trim() !== '') {
      setDirectionError(false);
    }
  };

  const addDirection = () => {
    setCookingDirection([...cookingDirection, '']);
  };

  const removeDirection = (index) => {
    const updatedDirections = [...cookingDirection];
    updatedDirections.splice(index, 1);
    setCookingDirection(updatedDirections);
    setDirectionError(false);
  };
  // Add Cooking Direction

  const handleSubmit = () => {
    setLoading(true);
    let isValid = true;
    if (recipeData.recipeTitle.trim() === '') {
      setLoading(false);
      setTitleError(true);
      isValid = false;
    }
    if (recipeData.makingDuration.trim() === '') {
      setLoading(false);
      setDurationError(true);
      isValid = false;
    }
    if (ingredients.some((ingredient) => ingredient.trim() === '')) {
      setLoading(false);
      setIngredientError(true);
      isValid = false;
    }
    if (cookingDirection.some((direction) => direction.trim() === '')) {
      setLoading(false);
      setDirectionError(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    const formData = new FormData();
    formData.append('recipeTitle', recipeData.recipeTitle);
    formData.append('makingDuration', recipeData.makingDuration);
    formData.append('indredient', JSON.stringify(ingredients));
    formData.append('cookingDirection', JSON.stringify(cookingDirection));
    formData.append('recipeImage', recipeData.recipeImage);

    AddPlan({
      url: 'recipe', // Update with your API endpoint
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Recipe Successfully Added', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
        setRecipeData({
          recipeTitle: '',
          makingDuration: '',
          recipeImage: null, // Set initial value to null
          imagePreview: null
        });
        setCookingDirection([]);
        setIngredients([]);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />

        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Recipe
          <IconButton
            aria-label="close"
            onClick={() => setModalopens(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="recipeTitle">Title</InputLabel>
                <OutlinedInput
                  id="recipeTitle"
                  type="text"
                  name="recipeTitle"
                  placeholder="Enter Title"
                  fullWidth
                  value={recipeData.recipeTitle}
                  onChange={handleChange}
                />
                {titleError && <FormHelperText error>Title is required</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="recipeTitle">Duration In Minutes</InputLabel>
                <OutlinedInput
                  id="recipeTitle"
                  type="number"
                  name="makingDuration"
                  placeholder="Enter Duration In Minutes"
                  fullWidth
                  value={recipeData.makingDuration}
                  onChange={handleChange}
                />
                {durationError && <FormHelperText error>Duration is Greater the 0 and less then 240 minutes</FormHelperText>}
              </Stack>
            </Grid>

            {ingredients?.map((ingredient, index) => (
              <Grid item xs={6} key={index}>
                <Stack spacing={1}>
                  <InputLabel htmlFor={`ingredient-${index}`}>{`Ingredient ${index + 1}`}</InputLabel>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OutlinedInput
                      id={`ingredient-${index}`}
                      type="text"
                      name="ingredient"
                      placeholder="Enter Ingredient"
                      fullWidth
                      value={ingredient}
                      onChange={(e) => handleChanges(e, index)}
                    />
                    <IconButton onClick={() => removeIngredient(index)}>
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            ))}

            <Grid item xs={12}>
              {ingredientError && <FormHelperText error>Ingredients are required</FormHelperText>}
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={addIngredient}>
                Add More Ingredient
              </Button>
            </Grid>

            {cookingDirection.map((cookingDirection, index) => (
              <Grid item xs={6} key={index}>
                <Stack spacing={1}>
                  <InputLabel htmlFor={`cookingDirection-${index}`}>{`Cooking Direction ${index + 1}`}</InputLabel>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OutlinedInput
                      id={`cookingDirection-${index}`}
                      type="text"
                      name="cookingDirection"
                      placeholder="Enter Cooking Direction"
                      fullWidth
                      value={cookingDirection}
                      onChange={(e) => handleChangeDirection(e, index)}
                    />
                    <IconButton onClick={() => removeDirection(index)}>
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            ))}
            <Grid item xs={12}>
              {directionError && <FormHelperText error>Cooking Direction are required</FormHelperText>}
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={addDirection}>
                Add More Directions
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '-5px'
                  }}
                  htmlFor="input-file"
                >
                  Select an image
                </label>
                {recipeData.imagePreview && (
                  <img src={recipeData.imagePreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                )}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Add Recipe
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default AddRecipesData;
