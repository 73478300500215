import { useRoutes } from 'react-router-dom';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const accessToken = localStorage.getItem('token')
  if (accessToken) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return  useRoutes([MainRoutes]);
  }else{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return  useRoutes([LoginRoutes]);
  }
}
