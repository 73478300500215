import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import usePost from 'hooks/usePost';
import { Box, Button, Grid, Stack, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { CloudUploadOutlined } from '@ant-design/icons';
import useGet from 'hooks/useGet';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function DietExcelSheet({ modalOpen, setModalOpen }) {
  const { mutateAsync: ExcelSheetUploadSheets } = usePost();
  const { mutateAsync: UserListGet } = useGet();
  const [userId, setUserId] = useState('');
  const [userList, setUserList] = useState('');
  const [importExcelFileData, setImportExcelFileData] = useState(null);

  const handleClose = () => {
    setModalOpen(false);
  };

  const excelSheetuploadSheet = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      defval: ''
    });
    setImportExcelFileData(file);

    // setImportExcelFileData(jsonData);
  };

  const excelSheetDriverupdate = (event) => {
    event.preventDefault();
    if (!importExcelFileData) {
      return;
    }
    const formData = new FormData();
    formData.append('excelFile', importExcelFileData);
    formData.append('userId', userId);
    ExcelSheetUploadSheets({
      url: 'diet-plan/upload-excel',
      type: 'details',
      token: true,
      payload: formData,
      file: true
    })
      .then((res) => {
        toast.success(' successfully Submit', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalOpen(false);
        setUserId('');
        setImportExcelFileData(null);
      })
      .catch((err) => {
        if (err?.data?.message == 'ERROR.MOBILE_NUMBER_ALREADY_EXIST') {
          alert('sdfk.shdjk');
          toast.error('MOBILE NUMBER ALREADY EXIST', {
            position: toast.POSITION.BOTTOM_RIGHT
          });
        }
      });
  };
  Button.propTypes = {
    bgcolor: PropTypes.string
  };
  const fetchData = async () => {
    try {
      const res = await UserListGet({
        url: `/users/user-list`,
        type: 'details',
        token: true
      });
      setUserList(res);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <React.Fragment>
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalOpen}>
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Diet Plan ExcelSheet Upload
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            x
          </IconButton>
          <DialogContent dividers>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="days">Select User</InputLabel>
                <Select
                  labelId="paymentMode-label"
                  id="Ddys"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  fullWidth
                  name="userId"
                >
                  {userList &&
                    userList?.map((item) => (
                      <MenuItem key={item?._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
            </Grid>
            <Box sx={{ marginLeft: 'auto', minWidth: '500px', py: 4 }}>
              <Grid>
                <form>
                  <Box
                    sx={{
                      m: '0 auto',
                      border: '1px solid #ddd',
                      borderRadius: '10px',
                      width: '202px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      height: '73px',
                      marginBottom: '20px'
                    }}
                  >
                    <input
                      style={{ position: 'absolute', opacity: 0, top: 0, bottom: 0, right: 0, left: 0 }}
                      id="fileInput"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => excelSheetuploadSheet(e)}
                    />
                    <CloudUploadOutlined />
                    <Box sx={{ ml: 2 }}> Upload ExcelSheet</Box>
                  </Box>
                  <Typography gutterBottom sx={{ textAlign: 'center' }}>
                    {importExcelFileData ? importExcelFileData?.name : 'Please select A Excel Sheet'}
                  </Typography>
                </form>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={excelSheetDriverupdate} variant="contained">
              Submit Excel Sheet
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
}

export default DietExcelSheet;
