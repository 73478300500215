import React from 'react';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
import MainCard from 'components/MainCard';
import Recipes from './Recipes';

const RecipesList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="Recipes" setOpenAddModal={setOpenAddModal}>
          <Recipes openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default RecipesList;
