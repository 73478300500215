import PropTypes from 'prop-types';
import { useState, useEffect, useRef, CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Drawer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  List,
  ListItem,
  FormControl,
  ListItemText,
  Select,
  Divider,
  MenuItem,
  ListItemIcon
} from '@mui/material';
import useDelete from 'hooks/useDelete';
import { useNavigate } from 'react-router-dom';
import Dot from 'components/@extended/Dot';
import useGet from 'hooks/useGet';
import { AllUserlist, MembershipPlan, UserUpdate } from 'constants/api';
import { FilterOutlined, EditOutlined, CloseOutlined, ReloadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { HeadCells, HeadCellsDaititian } from 'constants/Table-columns/TableColumn';
import Loading from 'pages/Loader/Loading';
import ConfirmModal from 'pages/dashboard/ConfirmModal';

function OrderTableHead({ order, orderBy }) {
  const Role = localStorage.getItem('role');

  return (
    <TableHead sx={{ backgroundColor: '#ddd' }}>
      <TableRow>
        {Role === '1' && (
          <>
            {HeadCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{ pt: '12px', pb: '12px' }}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </TableCell>
            ))}
          </>
        )}
        {Role === '3' && (
          <>
            {HeadCellsDaititian.map((headCell) => (
              <TableCell
                key={headCell.id}
                sx={{ pt: '12px', pb: '12px' }}
                align={headCell.align}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.label}
              </TableCell>
            ))}
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

const ProfileCompleted = ({ isCompleted }) => {
  let color;
  let title;

  switch (isCompleted) {
    case true:
      color = 'success';
      title = 'Completed';
      break;
    case false:
      color = 'error';
      title = 'Not Completed';
      break;
    default:
      color = 'primary';
      title = 'None';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

const OrderStatus = ({ isActive }) => {
  let color;
  let title;

  switch (isActive) {
    case true:
      color = 'success';
      title = 'Active';
      break;

    case false:
      color = 'error';
      title = 'In Active';
      break;

    default:
      color = 'primary';
      title = 'None';
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
};

OrderStatus.propTypes = {
  isActive: PropTypes?.bool
};

const DietPendings = () => {
  const { mutateAsync: UserListGet } = useGet();
  const [order] = useState('asc');
  const [orderBy] = useState('trackingNo');
  const [selected] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [statusfilter, setStatusfilter] = useState('');
  const [memberPlane, setMemberPlane] = useState('');
  const [dateRange, setDateRange] = useState(['', '']);
  const [startDate, endDate] = dateRange;
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const timerRef = useRef(null);
  const Role = localStorage.getItem('role');
  const [loading, setLoading] = useState(false);
  const { mutateAsync: DeleteMemberPlan } = useDelete();

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(() => {
      fetchData();
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [debouncedSearchQuery, currentPage, itemsPerPage, statusfilter, memberPlane, endDate, startDate]);

  const debouncedHandleSearch = debounce((value) => {
    setDebouncedSearchQuery(value);
  }, 1000);

  const fetchData = async () => {
    try {
      setLoading(true);
      let formattedStartDate, formattedEndDate;

      if (startDate === '') {
        formattedStartDate = '';
        formattedEndDate = '';
      } else {
        formattedStartDate = moment(startDate).format('YYYY-MM-DD');
        formattedEndDate = moment(endDate).format('YYYY-MM-DD');
      }
      const res = await UserListGet({
        url: `users/diet-pending-list?page=${currentPage}&limit=${itemsPerPage}&q=${debouncedSearchQuery}&isActive=${statusfilter}&planName=${memberPlane}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,

        type: 'details',
        token: true
      });
      setUserList(res);
      setTotalPages(Math.ceil(res.meta.totalItems / itemsPerPage));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;
  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const handleChange = (event) => {
    setMemberPlane(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setStatusfilter(event.target.value);
  };

  const [modalopens, setModalopens] = useState(false);
  const [editData, setEditData] = useState('');
  const [planAssingmodal, setPlanAssingmodal] = useState('');

  const assignPlan = (row) => {
    setModalopens(true);
    setEditData(row);
  };
  const Modalopen = (row) => {
    setModalopens(true);
    setEditData(row);
  };

  const filterSearch = () => {
    fetchData();
    setOpenDrawer(false);
  };
  const CloseDrower = () => {
    setOpenDrawer(false);
  };
  // *********************************
  const clearFilter = () => {
    setCurrentPage(1);
    setDebouncedSearchQuery('');
    setMemberPlane('');
    setSearchQuery('');
    setStatusfilter('');
    setDateRange(['', '']);
    fetchData();
  };
  // *********************************

  const navigate = useNavigate();
  const ViewProfilepage = (row) => {
    navigate('/user-profile/' + row?.id);
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [membershipdata, setMembershipdata] = useState();
  const deleteuser = (row) => {
    setMembershipdata(row);
    setDeleteModal(true);
  };
  const [memberplanName, setMemberplanName] = useState();
  const fetchPlandata = () => {
    UserListGet({
      url: MembershipPlan,
      type: 'details',
      token: true
    })
      .then((res) => {
        setMemberplanName(res);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchPlandata();
  }, []);
  const DleteMemberPlan = () => {
    DeleteMemberPlan({
      url: `${UserUpdate}${membershipdata?.id}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        toast.success('successfully deleted', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
      })
      .catch((err) => {
        toast.error('Not Deleted', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      });
  };
  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation">
      <Typography variant="h3" sx={{ textDecoration: 'none', px: 3, py: 2, position: 'relative' }}>
        <FilterOutlined /> Filter
        <Box sx={{ position: 'absolute', right: '10px', top: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={CloseDrower}>
          <CloseOutlined />
        </Box>
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label>Status Filter</label>
              <Select fullWidth sx={{ width: '100%' }} value={statusfilter} onChange={handleChangeStatus}>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label> Membership Plans</label>
              <Select fullWidth sx={{ width: '100%' }} value={memberPlane} onChange={handleChange}>
                {memberplanName?.map((data) => (
                  <MenuItem value={data?.planName}> {data?.planName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label>Registration Date</label>
              <div className="date-range-select">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                />
              </div>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider />
      </List>
    </Box>
  );
  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '100%',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            fullWidth
            label="Search By Name And Number"
            id="search"
            value={searchQuery}
            sx={{ width: 400 }}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              debouncedHandleSearch(e.target.value);
            }}
          />
          <div>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button onClick={toggleDrawer(true)} variant="contained">
                {' '}
                <FilterOutlined />
                &nbsp; Fillter
              </Button>
              <Button onClick={clearFilter} variant="contained">
                {' '}
                <ReloadOutlined /> &nbsp; Reset
              </Button>
            </Box>

            <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor="right">
              {DrawerList}
            </Drawer>
          </div>
        </Box>
        <TableContainer
          sx={{
            width: '100%',
            overflowX: 'auto',
            position: 'relative',
            display: 'block',
            maxWidth: '100%',
            '& td, & th': { whiteSpace: 'nowrap' }
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            sx={{
              '& .MuiTableCell-root:first-of-type': {
                pl: 2
              },
              '& .MuiTableCell-root:last-of-type': {
                pr: 3
              }
            }}
          >
            <OrderTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {userList?.items?.map((row, index) => {
                const isItemSelected = isSelected(row?.trackingNo);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    aria-checked={isItemSelected}
                    tabIndex={0}
                    key={row.index}
                    selected={isItemSelected}
                  >
                    <TableCell id={labelId} scope="row" align="left">
                      <Link color="secondary" component={RouterLink} to="">
                        {calculateSerialNumber(index)}
                      </Link>
                    </TableCell>

                    <TableCell align="left">{row?.name || '---'}</TableCell>
                    <TableCell align="left">{row?.mobileNumber}</TableCell>
                    <TableCell align="left">{row?.gender || '---'}</TableCell>
                    <TableCell align="left">
                      <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
                        {row?.userActivePlan?.[0]?.subsCription?.planName || '---'}
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      <div style={{ marginLeft: 'auto' }}>
                        {row?.userActivePlan?.[0]?.expiredDate
                          ? moment(row?.userActivePlan?.[0]?.expiredDate)?.format('DD-MMM-YYYY')
                          : '---'}
                      </div>
                    </TableCell>
                    <TableCell align="left">{row?.dietitan?.name || '---'}</TableCell>

                    <TableCell align="left">
                      <div style={{ marginLeft: 'auto' }}>
                        <ProfileCompleted isCompleted={row?.isCompleted} />
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      <div style={{ marginLeft: 'auto' }}>
                        <OrderStatus isActive={row?.isActive} />
                      </div>
                    </TableCell>
                    {Role === '1' && (
                      <TableCell
                        align="left"
                        sx={{
                          cursor: 'pointer'
                        }}
                      >
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                          {/* <span>
                            <EditOutlined onClick={() => assignPlan(row)} />
                          </span> */}
                          <span>
                            <EyeOutlined onClick={() => ViewProfilepage(row)} />
                          </span>
                          {/* <span>
                            <DeleteOutlined onClick={() => deleteuser(row)} />
                          </span> */}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 4, borderTop: '1px solid #ddd', pt: 4, justifyContent: 'end' }}>
          <Button variant="contained" disabled={currentPage === 1} onClick={handlePrevPage}>
            Previous
          </Button>
          <Typography>
            Page {currentPage} of {totalPages}
          </Typography>
          <Button variant="contained" disabled={currentPage === totalPages} onClick={handleNextPage}>
            Next
          </Button>
        </Stack>
      </Box>

      <Loading loading={loading} />
      <ConfirmModal
        deleteModal={deleteModal}
        SetDeleteModal={setDeleteModal}
        membershipdata={membershipdata}
        deleteplan={DleteMemberPlan}
        title={'User'}
      />
    </>
  );
};

export default DietPendings;
