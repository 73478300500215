import React from 'react';
import Dietician from './Dietician';
import MainCard from 'components/MainCard';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
const DieticianList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="Dietitian" setOpenAddModal={setOpenAddModal}>
          <Dietician openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default DieticianList;
