import React from 'react';
import MainCard from 'components/MainCard';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
import Configuration from './Configuration';

const ConfigurationList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);
  return (
    <>
      {' '}
      <ComponentSkeleton>
        <MainCard title="Configuration" setOpenAddModal={setOpenAddModal}>
          <Configuration openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default ConfigurationList;
