import React from 'react';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
import MainCard from 'components/MainCard';
import Storie from './Storie';

const StorieList = () => {
  const [openAddModal, setOpenAddModal] = React.useState(false);

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="Story" setOpenAddModal={setOpenAddModal}>
          <Storie openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default StorieList;
