import React, { useState } from 'react';
import { Button, Grid, Stack, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { CloseOutlined } from '@ant-design/icons';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePost from 'hooks/usePost';
import Loading from 'pages/Loader/Loading';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddConfiguration = ({ setModalopens, modalopens, fetchData }) => {
  const { mutateAsync: AddPlan } = usePost();
  const [loading, setLoading] = useState(false);
  const [configuration, setConfiguration] = useState({
    key: '',
    value: ''
  });
  const [errors, setErrors] = useState({
    key: false,
    value: false
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfiguration((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const errors = {};
    if (!configuration.key.trim()) {
      errors.key = true;
    }
    if (!configuration.value.trim()) {
      errors.value = true;
    }
    if (!configuration.key.trim() || !configuration.value.trim()) {
      setLoading(false);
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    AddPlan({
      url: 'configuration',
      type: 'details',
      payload: configuration,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Configuration Successfully Added', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
        setConfiguration({
          key: '',
          value: ''
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  const handleCloseAddConfiguration = () => {
    setModalopens(false);
    setConfiguration({
      key: '',
      value: ''
    });
  };
  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />

        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Configuration
          <IconButton
            aria-label="close"
            onClick={handleCloseAddConfiguration}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="key">Key</InputLabel>
                <OutlinedInput
                  id="key"
                  type="text"
                  name="key"
                  placeholder="Enter Key"
                  fullWidth
                  value={configuration.key}
                  onChange={handleChange}
                />
              </Stack>
              {errors.key && (
                <Typography variant="caption" color="error">
                  Key is required
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="value">Value</InputLabel>
                <OutlinedInput
                  id="value"
                  type="text"
                  name="value"
                  placeholder="Enter About Us"
                  fullWidth
                  value={configuration.value}
                  onChange={handleChange}
                />
              </Stack>
              {errors.value && (
                <Typography variant="caption" color="error">
                  Value is required
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleSubmit}>
            Add Configuration
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default AddConfiguration;
