import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePost from 'hooks/usePost';
import useGet from 'hooks/useGet';
import { toast } from 'react-toastify'; // Import toast for notifications
import Loading from 'pages/Loader/Loading';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const AddFollowUp = ({ addmodalopens, setAddModalopens, fetchdata }) => {
  const { mutateAsync: UserListGet } = useGet();

  const [userId, setUserId] = useState('');
  const defaultUserData = {
    userId: userId,
    freeText: ''
  };
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({ ...defaultUserData });
  const { mutateAsync: AddPlan } = usePost();
  const [userList, setUserList] = useState('');
  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    try {
      const res = await UserListGet({
        url: `/users/user-list`,
        type: 'details',
        token: true
      });
      setUserList(res);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setAddModalopens(false);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: ''
    }));
  };
  const validateForm = () => {
    const newErrors = {};
    if (!userId) newErrors.userId = 'User is required';
    if (!userData.freeText) newErrors.freeText = 'Message is required';
    return newErrors;
  };

  const submitForm = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setLoading(true);
    const payload = {
      userId: userId,
      freeText: userData?.freeText
    };
    AddPlan({
      url: 'follow-up-msg',
      type: 'details',
      payload: payload,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Successfully Added', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setAddModalopens(false);
        fetchdata();
        setUserId('');
        setUserData('');
      })
      .catch((err) => {
        toast.error('Server Error!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });

        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={addmodalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Add Membership Plan
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="days">Select User</InputLabel>
                <Select
                  labelId="paymentMode-label"
                  id="Ddys"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  fullWidth
                  name="userId"
                >
                  {userList && userList?.map((item) => <MenuItem value={item._id}>{item.name}</MenuItem>)}
                </Select>
                {errors.userId && <p style={{ color: 'red' }}>{errors.userId}</p>}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="freeText">Add Followw Up Message</InputLabel>
                <OutlinedInput
                  id="freeText"
                  type="text"
                  name="freeText"
                  placeholder="Membership name"
                  fullWidth
                  value={userData.freeText}
                  onChange={handleChange}
                />
                {errors.freeText && <p style={{ color: 'red' }}>{errors.freeText}</p>}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submitForm} variant="contained">
            Add Message
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};
export default AddFollowUp;
