import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  Table,
  Box,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  TextField,
  Button,
  Drawer,
  Typography
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useGet from 'hooks/useGet';
import { BookingListUrl } from 'constants/api';
import BookingStatusModal from './BookingStatusModal';
import Dot from 'components/@extended/Dot';
import { FilterOutlined, EditOutlined, CloseOutlined, ReloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from 'pages/Loader/Loading';
import * as XLSX from 'xlsx';

const Counselling = () => {
  const { mutateAsync: BookingListGet } = useGet();
  const timerRef = useRef(null);
  const [bookinglistshow, setBookinglistshow] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [statusfilter, setStatusfilter] = useState('');
  const [dateRange, setDateRange] = useState(['', '']);
  const [startDate, endDate] = dateRange;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openmodal, setOpenmodal] = useState();
  const [data, setdata] = useState();

  const fetchdata = () => {
    setLoading(true);
    let formattedStartDate, formattedEndDate;
    if (startDate === '') {
      formattedStartDate = '';
      formattedEndDate = '';
    } else {
      formattedStartDate = moment(startDate).format('YYYY-MM-DD');
      formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    }
    BookingListGet({
      url: `${BookingListUrl}?page=${currentPage}&limit=${itemsPerPage} &q=${searchQuery}&status=${statusfilter}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
      type: 'details',
      token: true
    })
      .then((res) => {
        setLoading(false);
        setBookinglistshow(res);
        setTotalPages(Math.ceil(res.meta.totalItems / itemsPerPage));
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchdata();
  }, [searchQuery, currentPage, itemsPerPage, statusfilter, startDate, endDate]);

  const StatusChange = (row) => {
    setOpenmodal(true);
    setdata(row);
  };

  const BookingStatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
      case 'Converted':
        color = 'success';
        title = 'Converted';
        break;
      case 'Interested':
        color = 'success';
        title = 'Interested';
        break;
      case 'Not Picked Up':
        color = 'error';
        title = 'Not Picked Up';
        break;
      case 'Unreachable':
        color = 'error';
        title = 'Unreachable';
        break;
      case 'Call Back':
        color = 'success';
        title = 'Call Back';
        break;
      case 'Not Interested':
        color = 'error';
        title = 'Not Not Interested';
        break;
      default:
        color = 'error';
        title = 'None';
    }

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Dot color={color} />
        <Typography>{title}</Typography>
      </Stack>
    );
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * itemsPerPage + index + 1;
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const clearFilter = () => {
    setStatusfilter('');
    setDateRange(['', '']);
    fetchdata();
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const CloseDrower = () => {
    setOpenDrawer(false);
  };

  const handleChangeStatus = (event) => {
    setStatusfilter(event.target.value);
  };

  const handleDownload = async () => {
    try {
      const response = await BookingListGet({
        url: 'booking/list?type=xlsx',
        type: 'details',
        token: true,
        file: true
      });

      const blob = new Blob([response], { type: 'application/octet-stream' });
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });

        // Adjust column widths based on data length
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const colWidths = jsonData[0].map((col, index) => {
            let maxLength = col.length;
            jsonData.forEach((row) => {
              if (row[index] && row[index].toString().length > maxLength) {
                maxLength = row[index].toString().length;
              }
            });
            return { wch: maxLength + 2 }; // Add extra space
          });

          worksheet['!cols'] = colWidths;
        });

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blobOut = new Blob([wbout], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blobOut);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'booking.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };

      reader.readAsArrayBuffer(blob);
    } catch (error) {
      console.error('Error downloading the Excel file:', error);
    }
  };
  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation">
      <Typography variant="h3" sx={{ textDecoration: 'none', px: 3, py: 2, position: 'relative' }}>
        <FilterOutlined /> Filter
        <Box sx={{ position: 'absolute', right: '10px', top: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={CloseDrower}>
          <CloseOutlined />
        </Box>
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label>Booking Status</label>
              <Select fullWidth sx={{ width: '100%' }} value={statusfilter} onChange={handleChangeStatus}>
                <MenuItem value={'Converted'}>Converted</MenuItem>
                <MenuItem value={'Interested'}>Interested</MenuItem>
                <MenuItem value={'Not Picked Up'}>Not Picked Up</MenuItem>
                <MenuItem value={'Unreachable'}>Unreachable</MenuItem>
                <MenuItem value={'Call Back'}>Call Back</MenuItem>
                <MenuItem value={'Not Interested'}>Not Interested</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider />

        <ListItem>
          <ListItemIcon sx={{ width: '100%', display: 'block', mt: 4, mb: 2 }}>
            <FormControl fullWidth>
              <label>Booking Date</label>
              <div className="date-range-select">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={true}
                />
              </div>
            </FormControl>
          </ListItemIcon>
          <ListItemText />
        </ListItem>

        <Divider />
      </List>
    </Box>
  );

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '100%',
          justifyContent: 'space-between'
        }}
      >
        <TextField
          fullWidth
          label="Search"
          id="search"
          value={searchQuery}
          sx={{ width: 400 }}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        <div>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button onClick={toggleDrawer(true)} variant="contained">
              <FilterOutlined />
              &nbsp; Fillter
            </Button>
            <Button onClick={clearFilter} variant="contained">
              <ReloadOutlined /> &nbsp; Reset
            </Button>
            <Button onClick={handleDownload} variant="contained">
              <FileExcelOutlined /> &nbsp; Excel Download
            </Button>
          </Box>

          <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor="right">
            {DrawerList}
          </Drawer>
        </div>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#eee' }}>
              <TableCell>S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="left">Mobile Number</TableCell>
              <TableCell align="left">Booking Date</TableCell>
              <TableCell align="left">Time Slot</TableCell>
              <TableCell align="left">Connect Date</TableCell>
              <TableCell align="left">User Type</TableCell>
              <TableCell align="left">Diet Plan Status</TableCell>
              <TableCell align="left">Booking Status</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookinglistshow?.items?.map((row, index) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell scope="row" align="left">
                  {calculateSerialNumber(index)}
                </TableCell>
                <TableCell scope="row">{row?.users?.name}</TableCell>
                <TableCell align="left">{row?.users?.mobileNumber}</TableCell>
                <TableCell align="left">{moment(row?.date).format('DD/MMM/YYYY')}</TableCell>
                <TableCell align="left">{row?.timeSlot}</TableCell>
                <TableCell align="left">{row?.addDate ? moment(row?.addDate).format('DD/MMM/YYYY') : '----'}</TableCell>
                <TableCell align="left">{row?.userType}</TableCell>
                <TableCell align="left">{row?.dietPlanStatus}</TableCell>
                <TableCell align="left">
                  <BookingStatus status={row?.status} />
                </TableCell>
                <TableCell align="left">
                  <Box>
                    <Box
                      onClick={() => {
                        StatusChange(row);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      <EditOutlined />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 4, borderTop: '1px solid #ddd', pt: 4, justifyContent: 'end' }}>
        <Button variant="contained" disabled={currentPage === 1} onClick={handlePrevPage}>
          Previous
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button variant="contained" disabled={currentPage === totalPages} onClick={handleNextPage}>
          Next
        </Button>
      </Stack>

      <BookingStatusModal openmodal={openmodal} setOpenmodal={setOpenmodal} data={data} fetchdata={fetchdata} />
      <Loading loading={loading} />
    </div>
  );
};

export default Counselling;
