import { CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Grid, Stack, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import usePatch from 'hooks/usePatch';
import { toast } from 'react-toastify'; // Import toast for notifications
import Loading from 'pages/Loader/Loading';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function DetailStorieData({ setModalopens, modalopens, fetchData, editData }) {
  const { mutateAsync: EditPlan } = usePatch();
  const [loading, setLoading] = useState(false);
  const defaultUserData = {
    id: '',
    storiesTitle: '',
    externalLink: '',
    storyEndDate: '',
    storyViewImg: null,
    storiesImage: null,
    isActive: false
  };
  const [userData, setUserData] = useState(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  const [endDate, setEndDate] = useState(userData ? userData.storyEndDate : ''); // Set default value

  useEffect(() => {
    setUserData(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  }, [editData]);

  const handleClose = () => {
    setModalopens(false);
  };

  const submitForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('id', userData?.id);
    formData.append('storiesTitle', userData?.storiesTitle);
    formData.append('externalLink', userData?.externalLink);
    formData.append('storyEndDate', endDate);
    formData.append('isActive', userData?.isActive);
    formData.append('storiesImage', userData?.storiesImage);
    formData.append('storyViewImg', userData?.storyViewImg);

    EditPlan({
      url: 'stories',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);

        toast.success('Story Successfully Updated', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
        setModalopens(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // Handle file input change

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Story
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="storiesTitle">Title : {userData?.storiesTitle}</InputLabel>
              </Stack>
            </Grid>

            {/* Link */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink">Link : {userData?.externalLink}</InputLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="externalLink" mb={1.5}>
                  End Date : {userData?.storyEndDate?.substring(0, 10)}
                </InputLabel>
              </Stack>
            </Grid>
            {/* isactive */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="isActive">Status : {userData?.isActive === true ? 'Active' : 'inActive'}</InputLabel>
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                Story Image
                <img
                  src={userData?.storiesImage}
                  crossOrigin="anonymous"
                  alt="Preview"
                  style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                Story View Image
                <img
                  src={userData?.storyViewImg}
                  crossOrigin="anonymous"
                  alt="Preview"
                  style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
