import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePatch from 'hooks/usePatch';
import { toast } from 'react-toastify'; // Import toast for notifications
import Loading from 'pages/Loader/Loading';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function EditDieticianData({ setModalopens, modalopens, fetchData, editData }) {
  const { mutateAsync: EditPlan } = usePatch();
  const [imagePreview, setImagePreview] = useState(null);
  const defaultUserData = {
    id: '',
    name: '',
    mobileNumber: '',
    status: '',
    password: ''
  };
  const [userData, setUserData] = useState(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setUserData(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  }, [editData]);

  const handleClose = () => {
    setModalopens(false);
  };

  // const handleChange = (e, index) => {
  //   const { name, value } = e.target;
  //   {
  //     setUserData((prevData) => ({
  //       ...prevData,
  //       [name]: value
  //     }));
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobileNumber') {
      const mobileNumber = value.replace(/\D/g, '');
      const limitedMobileNumber = mobileNumber.slice(0, 10);
      setUserData((prevData) => ({
        ...prevData,
        [name]: limitedMobileNumber
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const submitForm = () => {
    setLoading(true);
    const payload = {
      _id: userData?._id,
      name: userData?.name,
      mobileNumber: userData?.mobileNumber,
      status: userData?.status,
      password: userData?.password
    };
    EditPlan({
      url: 'admin',
      type: 'details',
      payload: payload,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Dietician Successfully Updated', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        fetchData();
        setModalopens(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // Handle file input change

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);

      setUserData((prevData) => ({
        ...prevData,
        image: file
      }));
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Edit Dietician
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="planName">Name</InputLabel>
                <OutlinedInput
                  id="planName"
                  type="text"
                  name="name"
                  placeholder="Enter Name"
                  fullWidth
                  defaltValue={userData?.name}
                  value={userData?.name}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            {/* making duration */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="durationInMonth">Mobile Number</InputLabel>
                <OutlinedInput
                  id="durationInMonth"
                  type="number"
                  name="mobileNumber"
                  placeholder="Enter Mobile Number"
                  fullWidth
                  defaltValue={userData?.mobileNumber}
                  value={userData.mobileNumber}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="durationInMonth">Password</InputLabel>
                <OutlinedInput
                  id="durationInMonth"
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  fullWidth
                  defaltValue={userData?.longDesc}
                  value={userData.longDesc}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            {/* isactive */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="isActive">Status</InputLabel>
                <Select labelId="isActive-label" id="isActive" value={userData.status} onChange={handleChange} fullWidth name="status">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submitForm} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
