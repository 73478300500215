import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import TransformationList from 'pages/Transformation/TransformationList';
import RecipesList from 'pages/Recipes/RecipesList';
import BookingData from 'pages/Booking/BookingData';
import UserProfile from 'pages/Users/UserProfile';
import DieticianList from 'pages/Dietician/DieticianList';
import DietPlanList from 'pages/DietPlan/DietPlanList';
import StorieList from 'pages/Stories/StorieList';
import ConfigurationList from 'pages/Configuration/ConfigurationList';
import ShowMeal from 'pages/MealPicture.js/ShowMeal';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const UserList = Loadable(lazy(() => import('pages/Users/UserList')));
const AddNotification = Loadable(lazy(() => import('pages/Notification')));
const MemberPlan = Loadable(lazy(() => import('pages/components-overview/MemberPlan')));
const ChangepassWord = Loadable(lazy(() => import('pages/authentication/ChangePassword')));
const ProfilePage = Loadable(lazy(() => import('pages/dashboard/ProfilePage')));
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  // path: '/dashboard',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '*',
      element: <DashboardDefault />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'user-list',
      element: <UserList />
    },
    {
      path: 'notification',
      element: <AddNotification />
    },
    {
      path: 'user-profile/:id',
      element: <UserProfile />
    },
    {
      path: 'membership-plan',
      element: <MemberPlan />
    },
    {
      path: 'booking',
      element: <BookingData />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },

    {
      path: 'change-password',
      element: <ChangepassWord />
    },
    {
      path: 'profile',
      element: <ProfilePage />
    },
    {
      path: 'transformation',
      element: <TransformationList />
    },
    {
      path: 'recipes',
      element: <RecipesList />
    },
    {
      path: 'dietitian',
      element: <DieticianList />
    },
    {
      path: 'diet-plan',
      element: <DietPlanList />
    },
    {
      path: 'Story',
      element: <StorieList />
    },
    {
      path: 'configuration',
      element: <ConfigurationList />
    },
    {
      path: 'meal-pictures',
      element: <ShowMeal />
    }
  ]
};

export default MainRoutes;
