import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Select, MenuItem, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import usePatch from 'hooks/usePatch';
import { toast } from 'react-toastify'; // Import toast for notifications
import Loading from 'pages/Loader/Loading';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export default function EditRecipesData({ setModalopens, modalopens, fetchData, editData }) {
  const { mutateAsync: EditPlan } = usePatch();
  const [imagePreview, setImagePreview] = useState(null);
  const [durationerror, setDurationnError] = useState(false);
  const defaultUserData = {
    id: '',
    recipeTitle: '',
    makingDuration: '',
    indredient: [''],
    cookingDirection: [''],
    isActive: false,
    recipeImage: null
  };
  const [userData, setUserData] = useState(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setUserData(editData ? { ...defaultUserData, ...editData } : defaultUserData);
  }, [editData]);

  const handleClose = () => {
    setModalopens(false);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === 'makingDuration') {
      const duration = parseInt(value, 10);

      if (duration > 240) {
        setDurationnError(true);
        console.error('Duration should be at least 240 minutes');
        return;
      } else {
        setDurationnError(false);
      }
    }

    if (name === 'indredient') {
      const updatedIngredients = [...userData.indredient];
      updatedIngredients[index] = value;
      setUserData((prevData) => ({
        ...prevData,
        [name]: updatedIngredients
      }));
    } else if (name === 'cookingDirection') {
      const updatedDirection = [...userData.cookingDirection];
      updatedDirection[index] = value;
      setUserData((prevData) => ({
        ...prevData,
        [name]: updatedDirection
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  // add and remove ingredients
  const addIngredients = () => {
    setUserData((prevData) => ({
      ...prevData,
      indredient: [...prevData?.indredient, '']
    }));
  };

  const removeIngredients = (index) => {
    const updatedIngredients = [...userData?.indredient];
    updatedIngredients.splice(index, 1);
    setUserData((prevData) => ({
      ...prevData,
      indredient: updatedIngredients
    }));
  };
  // add and remove ingredients

  // add and remove directions
  const addDirection = () => {
    setUserData((prevData) => ({
      ...prevData,
      cookingDirection: [...prevData?.cookingDirection, '']
    }));
  };

  const removeDirection = (index) => {
    const updatedDirection = [...userData?.cookingDirection];
    updatedDirection.splice(index, 1);
    setUserData((prevData) => ({
      ...prevData,
      cookingDirection: updatedDirection
    }));
  };
  // add and remove directions

  const submitForm = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('_id', userData?._id);
    formData.append('recipeTitle', userData?.recipeTitle);
    formData.append('makingDuration', userData?.makingDuration);
    formData.append('indredient', JSON.stringify(userData?.indredient));
    formData.append('cookingDirection', JSON.stringify(userData?.cookingDirection));
    formData.append('isActive', userData?.isActive);
    formData.append('recipeImage', userData?.recipeImage);

    EditPlan({
      url: 'recipe',
      type: 'details',
      payload: formData,
      file: true,
      token: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        setLoading(false);
        toast.success('Recipe Updated Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        setModalopens(false);
        fetchData();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  // Handle file input change

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Create a URL for preview
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);

      setUserData((prevData) => ({
        ...prevData,
        recipeImage: file
      }));
    }
  };
  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modalopens}>
        <Loading loading={loading} />
        <DialogTitle sx={{ m: 0, p: 2, maxWidth: { xs: 400, lg: 600 }, width: { xs: '100%', lg: 600 } }} id="customized-dialog-title">
          Edit Recipe
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            {/* Title */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="planName">Recipe Title</InputLabel>
                <OutlinedInput
                  id="planName"
                  type="text"
                  name="recipeTitle"
                  placeholder="Recipe Title"
                  fullWidth
                  defaltValue={userData?.recipeTitle}
                  value={userData?.recipeTitle}
                  onChange={handleChange}
                />
              </Stack>
            </Grid>

            {/* making duration */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="durationInMonth">Duration In Minutes</InputLabel>
                <OutlinedInput
                  id="durationInMonth"
                  type="number"
                  name="makingDuration"
                  placeholder="Duration In Minutes"
                  fullWidth
                  defaltValue={userData?.recipeTitle}
                  value={userData.makingDuration}
                  onChange={handleChange}
                />
                {durationerror && <FormHelperText error>Duration is less then 240 minutes</FormHelperText>}
              </Stack>
            </Grid>

            {/* Ingredients */}
            {userData?.indredient?.map((indredient, index) => (
              <Grid item xs={6} key={index}>
                <Stack spacing={1}>
                  <InputLabel htmlFor={`indredient-${index}`}>Ingredient {index + 1}</InputLabel>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OutlinedInput
                      id={`indredient-${index}`}
                      type="text"
                      name="indredient"
                      placeholder="Indredients"
                      fullWidth
                      value={indredient}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <IconButton onClick={() => removeIngredients(index)}>
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={addIngredients}>
                Add More
              </Button>
            </Grid>
            {/* Ingredients */}

            {/* cooking direction */}
            {userData?.cookingDirection?.map((cookingDirection, index) => (
              <Grid item xs={6} key={index}>
                <Stack spacing={1}>
                  <InputLabel htmlFor={`cookingDirection-${index}`}>Cooking Direction {index + 1}</InputLabel>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <OutlinedInput
                      id={`cookingDirection-${index}`}
                      type="text"
                      name="cookingDirection"
                      placeholder="Cooking Direction"
                      fullWidth
                      value={cookingDirection}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <IconButton onClick={() => removeDirection(index)}>
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" startIcon={<PlusOutlined />} onClick={addDirection}>
                Add More
              </Button>
            </Grid>
            {/* cooking direction */}

            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <input type="file" id="input-file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />

                <label
                  style={{
                    height: '41px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '15px',
                    color: 'black',
                    backgroundColor: '#d9d9d9',
                    marginTop: '27px'
                  }}
                  htmlFor="input-file"
                >
                  Select A Image
                </label>
                {!imagePreview && (
                  <img
                    src={userData?.recipeImage}
                    crossOrigin="anonymous"
                    alt="Preview"
                    style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                  />
                )}
                {imagePreview && (
                  <>
                    <img src={imagePreview} alt="Preview" style={{ height: '150px', width: '150px', objectFit: 'cover' }} />
                  </>
                )}
              </Stack>
            </Grid>
            {/* isactive */}
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="isActive">Status</InputLabel>
                <Select labelId="isActive-label" id="isActive" value={userData.isActive} onChange={handleChange} fullWidth name="isActive">
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </Stack>
            </Grid>

            {/* <input type="file" accept="image/*" onChange={handleFileChange} />
       
            <img src={userData.recipeImage} alt="preview" style={{ height: '100px', width: '100px' }} />
            <img src={editData?.recipeImage} crossOrigin="anonymous" alt="noimage" style={{ height: '100px', width: '100px' }} /> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submitForm} variant="contained">
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
