import ComponentSkeleton from '../components-overview/ComponentSkeleton';
import MainCard from 'components/MainCard';
import BookingList from './BookingList';

const BookingData = () => (
  <ComponentSkeleton>
    <MainCard title="">
      <BookingList />
    </MainCard>
  </ComponentSkeleton>
);

export default BookingData;
