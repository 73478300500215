import React, { useState, useEffect } from 'react';
import MainCard from 'components/MainCard';
import ComponentSkeleton from 'pages/components-overview/ComponentSkeleton';
import { Box, Grid, Stack, InputLabel, Select, MenuItem, ListItem, ListItemIcon, FormControl, ListItemText } from '@mui/material';
import DatePicker from 'react-datepicker';
import useGet from 'hooks/useGet';
import { format } from 'date-fns';

const ShowMeal = () => {
  const { mutateAsync: UserListGet } = useGet();
  const { mutateAsync: MealPictureGet } = useGet();
  const [userList, setUserList] = useState('');
  const [mealPicture, setMealPicture] = useState('');
  const [userId, setUserId] = useState('');
  const [dateRange, setDateRange] = useState(['', '']);
  // const [startDate, endDate] = dateRange;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchData = async () => {
    try {
      const res = await UserListGet({
        url: `/users/user-list`,
        type: 'details',
        token: true
      });
      setUserList(res);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleMealPicture = async () => {
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : '';
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : '';
      const res = await MealPictureGet({
        url: `/meal-picture/${userId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
        type: 'details',
        token: true
      });
      setMealPicture(res);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    handleMealPicture();
  }, [userId, startDate, endDate]);

  const formatDateTime = (isoString) => {
    if (!isoString) return '';
    const date = new Date(isoString);
    const formattedDate = format(date, 'dd MMM yyyy'); // Format date as "12 May 2024"
    const formattedTime = format(date, 'hh:mm a'); // Format time as "12:00 PM"
    return `${formattedDate} ${formattedTime}`; // Combine date and time
  };

  return (
    <>
      <ComponentSkeleton>
        <MainCard title="Meal Pictures" sx={{ overflow: 'auto' }}>
          <Box sx={{ height: 'calc(100vh - 180px)' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="days">Select User</InputLabel>
                  <Select
                    labelId="paymentMode-label"
                    id="Ddys"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    fullWidth
                    name="userId"
                  >
                    {userList &&
                      userList?.map((item) => (
                        <MenuItem key={item?._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <ListItem>
                  <ListItemIcon sx={{ width: '100%', display: 'block' }}>
                    <FormControl fullWidth>
                      <label>Choose Date</label>
                      <div className="date-range-select">
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setStartDate(update[0]);
                            setEndDate(update[1]);
                          }}
                          isClearable={true}
                        />
                      </div>
                    </FormControl>
                  </ListItemIcon>
                  <ListItemText />
                </ListItem>
              </Grid>
            </Grid>

            <Box>
              {mealPicture && mealPicture.length > 0 ? (
                mealPicture.map((item) => (
                  <Box key={item.id}>
                    <div>
                      <b>{formatDateTime(item?.createdAt)}</b>
                    </div>
                    <div>
                      {' '}
                      {item?.mealImage?.map((i, index) => (
                        <img
                          key={index}
                          src={i}
                          alt="noimage"
                          crossOrigin="anonymous"
                          style={{ height: '100px', width: '100px', marginLeft: '20px', marginTop: '10px' }}
                        />
                      ))}
                    </div>
                  </Box>
                ))
              ) : (
                <div>
                  <h1>No Data Found</h1>
                </div>
              )}
            </Box>
          </Box>
        </MainCard>
      </ComponentSkeleton>
    </>
  );
};

export default ShowMeal;
